import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  AdsDropDown,
  getSectionData,
  updateSection,
  ClassificationDropDown,
  CampaignDropDown
} from "../../../../service/apiService";
import Select from "react-select";
import { toast } from "react-toastify";
import ImageGallery from "../../../../components/Product/ImageGallery";
import VideoGallery from "../../../../components/Product/VideoGallery";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch";

const SectionEditModel = ({ isOpen, onRequestClose, sectionId, onSuccess, sectionGroupId, limit }) => {
  const [editFormData, setEditFormData] = useState({} || null);
  const [editAdsDropdown, setEditAdsDropdown] = useState([]);
  const [editSectionDropdownData, setEditSectionDropdownData] = useState([]);
  //single dropdown
  const [editSingleDropDown, setEditSingleDropDown] = useState([]);

  ///Image and vedio 
  const AppUrl = "https://folcon.saharaas.com/api/files/";
  const [editSelectedImageMedia, setEditSelectedImageMedia] = useState([]);
  console.log(editSelectedImageMedia)
  const [editSelectedVideoMedia, setEditSelectedVideoMedia] = useState([]);
  const [EditisImageGalleryOpen, setEditIsImageGalleryOpen] = useState(false);
  const [editCampaignData, seteditCampaignData] = useState([]);
  const [EditsVideoGalleryOpen, seteditIsVideoGalleryOpen] = useState(false);
  const [EditerrorMessage, setEditErrorMessage] = useState("");
  const [mapId, setMapId] = useState([]);



  const editHandleImageMediaSelect = (mediaUrls) => {
    const newSelectedMedia = [...editSelectedImageMedia, ...mediaUrls].slice(0, 12);
    setEditSelectedImageMedia(newSelectedMedia);
    setEditIsImageGalleryOpen(false);
  };

  const handleVideoMediaSelect = (mediaUrls) => {
    setEditSelectedVideoMedia(
      limit === 1 ? [mediaUrls[0]] : [...editSelectedVideoMedia, ...mediaUrls]
    );
    seteditIsVideoGalleryOpen(false);
  };
  const handleDeleteMedia = (type, index) => {
    if (type === "image") {
      setEditSelectedImageMedia(editSelectedImageMedia.filter((_, i) => i !== index));
    } else if (type === "video") {
      setEditSelectedVideoMedia(editSelectedVideoMedia.filter((_, i) => i !== index));
    }
  };

  const handleDeleteOnEdit = (key) => {
    setEditFormData({ ...editFormData, [key]: '' })
  }

  //  { getting section number }
  const extractSectionNumber = (input) => {
    const match = input.match(/section(\d+)_/);
    if (match) {
      return parseInt(match[1], 10);
    }
    return null;
  }

  //section id 
  const SectionId_Number = extractSectionNumber(sectionId);

  const token = localStorage.getItem("admin_token");

  ///handlesubmit for 1,3,10
  const handleChange_1310 = async (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  // HandleChange
  const handleChange = (selectedOption) => {
    const selectedValues = selectedOption
      ? selectedOption.map((option) => option.value)
      : [];
    setEditFormData((prevData) => ({
      ...prevData,
      ADS: selectedValues,
    }));
  };


  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  //handle change for section 5
  const handle_Chnage_05_Text_Field = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  // Fetching  dropdown data when the edit model is open 
  useEffect(() => {
    if (isOpen) {
      EditfetchAdsDropdown();
      editFetchAdsDropdownSingle();
      editFetchClassificationDropdown();
      EdicampaignDropdown();
    }
  }, [isOpen]);


  // {function to get the sectionMapId }
  const getSectionMapId = (response) => {
    const sectionsData = response.sections_data;
    if (sectionsData && sectionsData.length > 0) {
      return sectionsData;
    }
    return null;
  }
  const clearData = () => {
    setEditSelectedImageMedia([])
    setEditSelectedVideoMedia([])
    setEditFormData({})
  }

  // Api Called to fill the data In the sections
  useEffect(() => {
    if (SectionId_Number) {
      const fetchSectionDetails = async () => {
        const response = await getSectionData(token, sectionGroupId);
        if (response.data) {
          const data = response.data;
          const transform = getSectionMapId(data)
          setMapId(transform)
          const ads = data.sections_data?.length ? data.sections_data : [];
          // All data
          let obj = {}
          let obj_section_14 = []
          if (SectionId_Number === 0) {
            obj_section_14 = ads?.filter((item) => {
              let obj = {}
              obj["key_name"] = item?.key_name;
              obj["key_value"] = item?.key_value;
              return obj;
            })
          } else {
            ads?.map((item) => {
              if (item?.key_name === "File" && item?.key_value) {
                let arr = item?.key_value?.split(",")
                if (arr[0]?.includes('mp4')) {
                  setEditSelectedVideoMedia(arr)
                } else {
                  setEditSelectedImageMedia(arr)
                }
              }
              obj[item?.key_name === "CLASSIFI" ? "CLASSIFICATION" : item?.key_name] = item?.key_value
            })
          }
          setEditFormData({
            ...editFormData,
            ...obj
          });
        }
      };
      fetchSectionDetails();
    }
  }, [SectionId_Number, isOpen]);


  const section25Payload = (key) => {
    console.log(key);
    if (key === "File_Video") {
      return editSelectedVideoMedia?.join(",");
    } else if (key === "File") {
      return editSelectedImageMedia?.join(",");
    } else {
      return editFormData[key];
    }
  };


  // Dynamic PlayLoad for sectins 
  const createPayload = (id, key) => {
    console.log(key)
    switch (id) {
      case 1:
        return key === "TITLE" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : editSelectedImageMedia[0]
      case 3:
      case 10:
      case 16:
        return key === "TITLE" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : editFormData[key];
      case 12:
        return key === "TITLE" ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : editFormData[key];
      case 8:
        return key === "TITLE_1" ? editFormData[key] : key === "TITLE_2" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : editFormData[key];
      case 2:
        return key === "TITLE" ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : editSelectedImageMedia[0];
      case 15:
        return key === "TITLE" ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : editFormData[key];
      case 9:
        return key === "TITLE" ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : typeof (editFormData[key]) === 'string' ? editFormData[key] : editFormData[key]?.join(",");
      case 19:
        return key === "TITLE" ? editFormData[key] : typeof (editFormData[key]) === 'string' ? editFormData[key] : editFormData[key]?.join(",");
      case 5:
        return key === "TITLE" ? editFormData[key] : typeof (editFormData[key]) === 'string' ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : editFormData[key];
      case 7:
        return key === "TITLE" ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : key === "ADS" ? editFormData[key].toString() : editFormData[key];
      case 4:
        return key === "TITLE" ? editFormData[key] : key === "CATEGORIES" ? editFormData[key].toString() : null;
      case 13:
        return key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : editFormData[key];
      case 18:
        return key === "TITLE_1" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : editFormData[key]
      case 26:
        return key === "TITLE" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : editFormData[key]
      case 20:
        return `${editFormData[key]}`;
      case 21:
        return `${editFormData[key]}`
      case 22:
        return key === "TITLE" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : editFormData[key];
      case 23:
        return key === "TITLE" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : editFormData[key];
      case 25:
        return section25Payload(key)
      case 28:
        return key === "TITLE" ? editFormData[key] : key === "CATEGORIES" ? editFormData[key].toString() : section25Payload(key);
      case 14:
        return key === "TITLE" ? editFormData[key] : key === "BRAND" ? editFormData[key].toString() : null;
      case 27:
        return key === "TITLE" ? editFormData[key] : key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : key === "ADS" ? editFormData[key].toString() : editSelectedImageMedia[0];
      case 34:
        return key === "TITLE" ? editFormData[key] : key === "TITLE_1" ? editFormData[key] : key === "BGCOLOR" ? editFormData[key] : key === "CATEGORIES" ? editFormData[key].toString() : null;
      case 29:
        return key === "BGCOLOR" ? editFormData[key] : key === "TITLE" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : null
      case 11:
        return key === "TITLE_1" ? editFormData[key] : key === "ADS" ? editFormData[key].toString() : editFormData[key];
      case 6:
        return key === "TITLE_1" ? editFormData[key] : key === "TITLE_2" ? editFormData[key] : key === "CAMP" ? editFormData[key].toString() : null;
      case 17:
        return key === "TITLE" ? editFormData[key] : key === "SPIN" ? editFormData[key].toString() : null;
      default:
        return '';
    }
  };





  const extractSectionMapId = (key) => {
    let keyName = key;
    if (key === "File_Video") {
      keyName = "File"
    }
    let obj = mapId?.find(item => {
      console.log(item?.key_name, keyName)
      if (item?.key_name === keyName) return item;
    })
    return obj ? obj?.section_map_id : -1
  }

  // Api  UpdateSection
  const handleEditSectionSubmit = async (e, key) => {
    e.preventDefault();
    console.log(key)
    try {
      let payload = {
        section_group: sectionGroupId,
        section_map_id: extractSectionMapId(key),
        key_value: createPayload(SectionId_Number, key),
        // is_active: onSuccess
      };
      console.log("payload", payload);
      const res = await updateSection(token, payload);
      if (res) {
        toast.success("Section updated successfully!");
        clearData();
        onRequestClose();
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Failed to update section.");
    }
  };


  // API fetched  DATA FOR THE DROPDOWN
  const EditfetchAdsDropdown = async () => {
    const response = await AdsDropDown(token);
    setEditAdsDropdown(
      response.data.map((option) => ({
        value: option.id,
        label: option.name.toUpperCase(),
      }))
    );
  };


  const EdicampaignDropdown = async () => {
    const response = await CampaignDropDown(token);
    seteditCampaignData(
      response.data.map((option) => ({
        value: option.campaign_id,
        label: option.campaign_name.toUpperCase(),
      }))
    );
  };


  //API fetched for single dropdown data 
  const editFetchAdsDropdownSingle = async () => {
    const response = await AdsDropDown(token);
    setEditSingleDropDown(response.data);
  };

  // Api fetched for classifications
  const editFetchClassificationDropdown = async () => {
    const classifications = await ClassificationDropDown(token);
    console.log(classifications?.data)
    setEditSectionDropdownData(
      classifications.data.map((option) => ({
        value: option.classification_id,
        label: option.classification_name.toUpperCase(),
      }))
    );
  };

  const onSingleUpdate = (url) => {
    setEditFormData({ ...editFormData, "File": url })
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Edit Section"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "15px",
            padding: "20px",
            maxWidth: "1500px",
            minWidth: "1500px",
            maxHeight: "700px",
            minHeight: "800px",
            overflowY: "auto",
            boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)",
            border: "2px solid #63E6BE",
          },
        }}
      >
        <div className="modal-header">
          <h2>Edit Section</h2>
          <button
            onClick={() => {
              onRequestClose();
              clearData();
            }}
          >
            Close
          </button>
        </div>
        {/* Section Input fields */}
        <form>
          {EditerrorMessage && <div className="error-message">{EditerrorMessage}</div>}
          {[1].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>

              </div>

              {/* {ADS} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={handleChange}
                  options={editAdsDropdown}
                  isMulti
                />
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>

              {/* {  Backgournd Image} */}
              {limit === 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => setEditIsImageGalleryOpen(true)}
                  >
                    Image Gallery - BG_IMG
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      setEditIsImageGalleryOpen(editSelectedImageMedia.length === 0)
                    }
                    disabled={editSelectedImageMedia.length > 0}
                  >
                    Image Gallery - BG_IMG
                  </button>
                </>
              )}

            </div>
          )}
          {[2].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {title} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <select
                  id="adsDropdown2"
                  name="ADS"
                  value={editFormData.ADS || ""}
                  onChange={handleChange_1310}
                  className="dropdown"
                >
                  <option value="">Select an Ads</option>
                  {editSingleDropDown.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name.toUpperCase()}
                    </option>
                  ))}
                </select>
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
              <div>
                <div className="dropdown-container">
                  <label htmlFor="titleInput">Link</label>
                  <input
                    id="titleInput1"
                    type="text"
                    name="LINK"
                    value={editFormData?.LINK}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "LINK")}>
                    Edit Link
                  </button>
                </div>
                {/* {  Backgournd Image} */}
                {limit === 0 ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() => setEditIsImageGalleryOpen(true)}
                    >
                      Image Gallery - BG_IMG
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() =>
                        setEditIsImageGalleryOpen(editSelectedImageMedia.length === 0)
                      }
                      disabled={editSelectedImageMedia.length > 0}
                    >
                      Image Gallery - BG_IMG
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          {[3].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {descripition} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput"> Descripition</label>
                <input
                  id="titleInput"
                  type="text"
                  name="DESC"
                  value={editFormData.DESC}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "DESC")}>
                  Edit
                </button>
              </div>
              {/* {ADS} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={handleChange}
                  options={editAdsDropdown}
                  isMulti
                />
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}
          {[4].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Categories Toggele</label>
                <ToggleSwitch
                  checked={editFormData.CATEGORIES == "1"}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CATEGORIES: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CATEGORIES")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[5].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[6].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: 1 </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title:2 </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_2"
                  value={editFormData.TITLE_2}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_2")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="Campaign">CAMPAIGN</label>
                <Select
                  id="CampaignDropdown"
                  name="CAMP"
                  value={editCampaignData.filter(
                    (option) => option.value === parseInt(editFormData.CAMP)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CAMP: selectedOption.value,
                    }))
                  }
                  options={editCampaignData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CAMP")}>
                  Edit
                </button>
              </div>

            </div>


          )}
          {[7].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {Ads} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <select
                  id="adsDropdown2"
                  name="ADS"
                  value={editFormData.ADS || ""}
                  onChange={handleChange_1310}
                  className="dropdown"
                >
                  <option value="">Select an Ads</option>
                  {editSingleDropDown.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name.toUpperCase()}
                    </option>
                  ))}
                </select>
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>

              {/* {classification} */}
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>

              {/* {link} */}
              <div className="dropdown-container">
                <label htmlFor="titleInput">Link</label>
                <input
                  id="titleInput1"
                  type="text"
                  name="LINK"
                  value={editFormData?.LINK}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title here"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "LINK")}>
                  Edit Link
                </button>
              </div>
            </div>
          )}
          {[8].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title:1 </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>
              {/* ADS */}
              <label htmlFor="editAdsDropdown">Ads</label>
              <Select
                id="editAdsDropdown"
                name="ADS"
                value={
                  editFormData.ADS
                    ? editAdsDropdown.filter((option) =>
                      editFormData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 5) {
                    handleChange(selectedOption);
                    setEditErrorMessage("");
                  } else {
                    setEditErrorMessage("You can select a maximum of 5 ads.");
                  }
                }}
                options={editAdsDropdown}
                isMulti
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                Edit
              </button>



              {/* TITLE 2 */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: 2 </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE_2}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_2")}>
                  Edit
                </button>
              </div>

            </div>
          )}
          {[9].includes(SectionId_Number) && (
            <div>
              {/* {TITLE-1} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE1"
                  value={editFormData.TITLE1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE1")}>
                  Edit
                </button>
              </div>
              {/* {TITLE-2} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE2"
                  value={editFormData.TITLE2}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE2")}>
                  Edit
                </button>
              </div>

              {/* {DESCRIPTION} */}
              <div>
                <label htmlFor="titleInput">Description</label>
                <input
                  id="titleInput1"
                  type="text"
                  name="DESCRIPTION"
                  value={editFormData.DESCRIPTION}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter description here"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "DESCRIPTION")}>
                  Edit
                </button>
              </div>
              {/* {CLASSIFICATION} */}
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[10].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* classification */}

              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[11].includes(SectionId_Number) && (
            <div>
              {/* {TITLE-1} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <label htmlFor="editAdsDropdown">Ads</label>
              <Select
                id="editAdsDropdown"
                name="ADS"
                value={
                  editFormData.ADS
                    ? editAdsDropdown.filter((option) =>
                      editFormData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 3) {
                    handleChange(selectedOption);
                    setEditErrorMessage("");
                  } else {
                    setEditErrorMessage("You can select a maximum of 3 ads.");
                  }
                }}
                options={editAdsDropdown}
                isMulti
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                Edit
              </button>
            </div>
          )}
          {[12].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* classification */}
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[13].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {Link} */}
              <div className="dropdown-container">
                <label htmlFor="titleInput">Link</label>
                <input
                  id="titleInput1"
                  type="text"
                  name="LINK"
                  value={editFormData?.LINK}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title here"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "LINK")}>
                  Edit Link
                </button>
              </div>

              {/* {ADS} */}
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={handleChange}
                  options={editAdsDropdown}
                  isMulti
                />
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>

            </div>
          )}
          {[14].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Brand Toggle</label>
                <ToggleSwitch
                  checked={editFormData.BRAND == "1"}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      BRAND: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "BRAND")}>
                  Edit
                </button>
              </div>

            </div>
          )}
          {[15].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {Link} */}
              <div className="dropdown-container">
                <label htmlFor="titleInput">Link</label>
                <input
                  id="titleInput1"
                  type="text"
                  name="LINK"
                  value={editFormData?.LINK}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title here"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "LINK")}>
                  Edit Link
                </button>
              </div>


              {/* classification */}
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[16].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">

              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <label htmlFor="editAdsDropdown">Ads</label>
              <Select
                id="editAdsDropdown"
                name="ADS"
                value={
                  editFormData.ADS
                    ? editAdsDropdown.filter((option) =>
                      editFormData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 5) {
                    handleChange(selectedOption);
                    setEditErrorMessage("");
                  } else {
                    setEditErrorMessage("You can select a maximum of 5 ads.");
                  }
                }}
                options={editAdsDropdown}
                isMulti
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                Edit ADS
              </button>



              {/* {Link} */}
              <div className="dropdown-container">
                <label htmlFor="titleInput">Link</label>
                <input
                  id="titleInput1"
                  type="text"
                  name="LINK"
                  value={editFormData?.LINK}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title here"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "LINK")}>
                  Edit Link
                </button>
              </div>


            </div>
          )}
          {[17].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">SPINNER</label>
                <ToggleSwitch
                  checked={editFormData.SPIN == "1"}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      SPIN: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "SPIN")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[18].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <div>
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.length <= 6) {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    } else {
                      setEditErrorMessage("You can select a maximum of 6 ads.");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[19].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <label htmlFor="editAdsDropdown">Ads</label>
              <Select
                id="editAdsDropdown"
                name="ADS"
                value={
                  editFormData.ADS
                    ? editAdsDropdown.filter((option) =>
                      editFormData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 7) {
                    handleChange(selectedOption);
                    setEditErrorMessage("");
                  } else {
                    setEditErrorMessage("You can select a maximum of 7 ads.");
                  }
                }}
                options={editAdsDropdown}
                isMulti
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                Edit
              </button>
            </div>
          )}
          {[20].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <div>
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.length <= 9) {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    } else {
                      setEditErrorMessage("You can select a maximum of 9 ads.");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[21].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput1">Title: </label>
                <input
                  id="titleInput1"
                  type="text"
                  value={editFormData?.TITLE}
                  name="TITLE"
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title 1"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit Title
                </button>
              </div>
              <div className="input-container form-group">
                {/* {ADS} */}
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.length <= 6) {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    } else {
                      setEditErrorMessage("You can select a maximum of 6 ads.");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Edit ADS
                </button>
              </div>
            </div>
          )
          }
          {[22].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">
              <div>
                <label htmlFor="titleInput">Title </label>
                <input
                  id="titleInput1"
                  type="text"
                  name="TITLE"
                  value={editFormData?.TITLE}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title here"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit Title
                </button>
              </div>
              {/* {ADS} */}
              <label htmlFor="editAdsDropdown">Ads</label>
              <Select
                id="editAdsDropdown"
                name="ADS"
                value={
                  editFormData.ADS
                    ? editAdsDropdown.filter((option) =>
                      editFormData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 10) {
                    handleChange(selectedOption);
                    setEditErrorMessage("");
                  } else {
                    setEditErrorMessage("You can select a maximum of 10 ads.");
                  }
                }}
                options={editAdsDropdown}
                isMulti
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                Edit
              </button>


            </div>
          )}
          {[23].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput1">Title: </label>
                <input
                  id="titleInput1"
                  type="text"
                  value={editFormData?.TITLE}
                  name="TITLE"
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title 1"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit Title
                </button>
              </div>
              <div className="input-container form-group">
                {/* {ADS} */}
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.length <= 6) {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    } else {
                      setEditErrorMessage("You can select a maximum of 6 ads.");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Edit ADS
                </button>
              </div>
            </div>
          )
          }
          {[25].includes(parseInt(SectionId_Number)) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {image} */}
              {limit === 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => seteditIsVideoGalleryOpen(true)}
                  >
                    Video Gallery
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      seteditIsVideoGalleryOpen(editSelectedVideoMedia.length === 0)
                    }
                    disabled={editSelectedVideoMedia.length > 0}
                  >
                    Video Gallery
                  </button>
                </>
              )}
            </div>
          )}
          {[26].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <div>
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption.length <= 5) {
                      handleChange(selectedOption);
                      setEditErrorMessage("");
                    } else {
                      setEditErrorMessage("You can select a maximum of 5 ads.");
                    }
                  }}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[27].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
                <div className="dropdown-container">
                  <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                  <Select
                    id="ClassificationDropdown"
                    name="CLASSIFI"
                    value={editSectionDropdownData.filter(
                      (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                    )}
                    onChange={(selectedOption) =>
                      setEditFormData((prevData) => ({
                        ...prevData,
                        CLASSIFICATION: selectedOption.value,
                      }))
                    }
                    options={editSectionDropdownData}
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                    Edit
                  </button>
                </div>

                <div className="dropdown-container">
                  <label htmlFor="editAdsDropdown">Ads</label>
                  <select
                    id="adsDropdown2"
                    name="ADS"
                    value={editFormData.ADS || ""}
                    onChange={handleChange_1310}
                    className="dropdown"
                  >
                    <option value="">Select an Ads</option>
                    {editSingleDropDown.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name.toUpperCase()}
                      </option>
                    ))}
                  </select>
                  <div>
                    <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                      Edit
                    </button>
                  </div>
                </div>
                {/* {  Backgournd Image} */}
                {limit === 0 ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() => setEditIsImageGalleryOpen(true)}
                    >
                      Image Gallery - BG_IMG
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() =>
                        setEditIsImageGalleryOpen(editSelectedImageMedia.length === 0)
                      }
                      disabled={editSelectedImageMedia.length > 0}
                    >
                      Image Gallery - BG_IMG
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          {[28].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              {/* {vedio} */}
              {limit === 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => seteditIsVideoGalleryOpen(true)}
                  >
                    Video Gallery
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      seteditIsVideoGalleryOpen(editSelectedVideoMedia.length === 0)
                    }
                    disabled={editSelectedVideoMedia.length > 0}
                  >
                    Video Gallery
                  </button>

                </>
              )}

              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Categories Toggele</label>
                <ToggleSwitch
                  checked={editFormData.CATEGORIES == "1"}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CATEGORIES: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CATEGORIES")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[29].includes(SectionId_Number) && (

            <div>
              {/* {TITLE} */}
              < label htmlFor="titleInput">Title: 1 </label>
              <input
                id="titleInput"
                type="text"
                name="TITLE"
                value={editFormData.TITLE}
                onChange={handle_Chnage_05_Text_Field}
                className="form-control"
                placeholder="Enter title"
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                Edit
              </button>


              {/* {color} */}

              <div className="input-container form-group">
                <label htmlFor="titleInput">Color </label>
                <input
                  id="titleInput"
                  type="text"
                  name="Color"
                  value={editFormData.BGCOLOR}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "BGCOLOR")}>
                  Edit
                </button>
              </div>

              {/* {ADS} */}
              <label htmlFor="editAdsDropdown">Ads</label>
              <Select
                id="editAdsDropdown"
                name="ADS"
                value={
                  editFormData.ADS
                    ? editAdsDropdown.filter((option) =>
                      editFormData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 6) {
                    handleChange(selectedOption);
                    setEditErrorMessage("");
                  } else {
                    setEditErrorMessage("You can select a maximum of 6 ads.");
                  }
                }}
                options={editAdsDropdown}
                isMulti
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                Edit ADS
              </button>
            </div>
          )}
          {[34].includes(SectionId_Number) && (
            <div>
              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: 1 </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>

              </div>

              {/* {TITLE} */}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: 2 </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>

              {/* {color} */}

              <div className="input-container form-group">
                <label htmlFor="titleInput">Color </label>
                <input
                  id="titleInput"
                  type="text"
                  name="Color"
                  value={editFormData.BGCOLOR}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "BGCOLOR")}>
                  Edit
                </button>
              </div>


              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Categories Toggele</label>
                <ToggleSwitch
                  checked={editFormData.CATEGORIES == "1"}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CATEGORIES: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CATEGORIES")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {/* ************************************* */}
          <div className="media-preview-container">
            {editSelectedImageMedia.length > 0 && (
              <div className="media-preview">
                <h3>Selected Images</h3>
                <div className="media-items">
                  {editSelectedImageMedia.map((mediaUrl, index) => (
                    <div key={index} className="media-item">
                      <img
                        src={AppUrl + mediaUrl}
                        alt="Selected"
                        width={"250px"}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteMedia("image", index)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <button onClick={(e) => handleEditSectionSubmit(e, "File")}>
                    Edit
                  </button>
                </div>
              </div>
            )}
            {editSelectedVideoMedia.length > 0 && (
              <div className="media-preview">
                <h3>Selected Videos</h3>
                <div className="media-items">
                  {editSelectedVideoMedia.map((mediaUrl, index) => (
                    <div key={index} className="media-item">
                      <video
                        src={AppUrl + mediaUrl}
                        controls
                        width={"250px"}
                        height={"200px"}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteMedia("video", index)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <button onClick={(e) => handleEditSectionSubmit(e, "File_Video")}>
                    Edit
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
        <ImageGallery
          //   isOpen={EditisImageGalleryOpen}
          //   onRequestClose={() => setEditIsImageGalleryOpen(false)}
          //   onMediaSelect={onSingleUpdate}
          //   selectedMedia={editSelectedImageMedia}
          //   setSelectedMedia={setEditSelectedImageMedia}
          //   limit={3}
          //   sectionId={sectionId}
          //   SectionId_Number={SectionId_Number}
          // // onSingleUpdate={onSingleUpdate}
          isOpen={EditisImageGalleryOpen}
          onRequestClose={() => setEditIsImageGalleryOpen(false)}
          onMediaSelect={editHandleImageMediaSelect}
          selectedMedia={editSelectedImageMedia}
          setSelectedMedia={setEditSelectedImageMedia}
          limit={3}
          sectionId={sectionId}
          SectionId_Number={SectionId_Number}
        />
        <VideoGallery
          isOpen={EditsVideoGalleryOpen}
          onRequestClose={() => seteditIsVideoGalleryOpen(false)}
          onMediaSelect={handleVideoMediaSelect}
          selectedMedia={editSelectedVideoMedia}
          setSelectedMedia={setEditSelectedVideoMedia}
          limit={limit}
        />
      </Modal >
    </>
  );
};
export default SectionEditModel;







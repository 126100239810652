import React, { useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDataTable from '../../../../components/DataTable';
import ReactPaginate from 'react-paginate';
import {getAllBlogsAPICall, getBlogAPICall } from "../../../../service/apiService";
import AdminFooter from "../../Dashboard/AdminFooter";
import AdminHeader from "../../Dashboard/AdminHeader";
import SideBar from "../../Dashboard/SideBar";
import noImage from "../../../../assets/images/noimage.jpg";
import BlogEdit from "./BLogEdit";
const AppUrl = "https://folcon.saharaas.com/api/files/";

const BlogList = () => {
    const [globalFilter, setGlobalFilter] = useState('');
    const [blogCategories, setBlogCategories] = useState([]);
    const [editBlogForm, setEditBlogForm] = useState(false); 
    const [editFormData, setEditFormData] = useState([]);
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;
    const pageCount = Math.ceil(blogCategories.length / itemsPerPage);
    const currentBlogCategories = blogCategories.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    useEffect(() => {
        getAllBlogs();
    }, []);

    const getAllBlogs = async () => {
        try {
            const token = localStorage.getItem('admin_token');
            const response = await getAllBlogsAPICall(token);
            if (response) {
                setBlogCategories(response.data);
                console.log('getAllBlogCategoriesAPICall', response.data);
            }
        } catch (error) {
            console.error('getAllBlogCategories', error);
        }
    };

    const handleEdit = async (blogId) => {
        try {
            const token = localStorage.getItem('admin_token');
            const response = await getBlogAPICall(token, blogId);
            const responseData = response.data;
            if (response.status_code === 200) {
                console.log('getBlogAPICall', responseData);
                 setEditBlogForm(true);
                setEditFormData(responseData)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
 

    const columns = [
        {
            field: 'id', header: 'ID',
            body: (rowData, index) => (
                <span>{index.rowIndex + 1}</span>
            ),
        },
        { field: 'title', header: 'Blog Category Name' },
        { field: 'blog_category_name', header: 'Blog Category Name' },
        { field: 'blog_tag_name', header: 'Blog Tag Name' },
        // { field: 'message', header: 'Message' ,body: (rowData) => (
        //     console.log('rowData.rowData',rowData)
        // )},   
        {
            field: "thumnail_image",
            header: "Image",
            body: (rowData) => (
                <img
                    src={AppUrl + rowData.image_path || noImage}
                    width={100}
                    height={100}
                    alt="Product"
                />
            ),
        },
        // { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{console.log(rowData)}{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.blog_id)}>Edit info</a>
                            {/* <a className="dropdown-item text-danger" style={{ cursor: 'pointer' }} onClick={() => handleDelete(rowData.id)}>Delete</a> */}
                        </div>
                    </div>
                </div>
            )
        }
    ];


    const header = (
        <div className="table-header">
            <div>
                <h3>Blog categories</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="search blog categories"
                        />
                    </span>
                </div>
            </div>
            <div className="create-category-button">
                <button onClick={() => navigate('/admin/blogs/blogCategory/create')} className="btn btn-primary">Create blog category</button>
            </div>
        </div>
    );

    return (
        <div>
            <AdminHeader />
            <SideBar />
            {!editBlogForm ?
                <main className="main-wrap">
                    <main id="main" className="main">
                        <div className="pagetitle">
                            <h1>Blog List</h1>
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="/dashboard">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item">Blogs</li>
                                    <li className="breadcrumb-item active">Blog</li>
                                    <li className="breadcrumb-item active">List</li>
                                </ol>
                            </nav>
                        </div>
                        {/* End Page Title */}
                        <section className="section">
                            <ReactDataTable
                                data={currentBlogCategories}
                                globalFilter={globalFilter}
                                setGlobalFilter={setGlobalFilter}
                                header={header}
                                emptyMessage="No blog records found"
                                columns={columns}
                            />
                            <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={'pagination'}
                                activeClassName={'active'}
                            />
                        </section>
                        <ToastContainer />

                    </main>
                </main> :
                <BlogEdit editFormData={editFormData} setEditBlogForm={setEditBlogForm} getAllBlogs={getAllBlogs}/>
            }
            <AdminFooter />
        </div>


    );
}

export default BlogList;

import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  AdsDropDown,
  CampaignDropDown,
  ClassificationDropDown,
  addSection,
} from "../../../../service/apiService";
import "../../../../assets/css/section.css";
import { toast } from "react-toastify";
import VideoGallery from "../../../../components/Product/VideoGallery";
import ImageGallery from "../../../../components/Product/ImageGallery";
import Select from "react-select";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch";

const SectionCreate = ({
  isOpen,
  onRequestClose,
  onSave,
  sectionId,
  onSuccess,
  limit,
}) => {
  const pageid = localStorage.getItem("pageid");
  const [formData, setFormData] = useState({});
  const [adsDropdown, setAdsDropdown] = useState([]);
  const [sectionDropdownData, setSectionDropdownData] = useState([]);
  const [selectedImageMedia, setSelectedImageMedia] = useState([]);
  const [selectedVideoMedia, setSelectedVideoMedia] = useState([]);
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const [isVideoGalleryOpen, setIsVideoGalleryOpen] = useState(false);
  const [campaignData, setCampaignData] = useState([]);

  const [section23ImgId, setSection23ImgId] = useState(null);
  const [sec23, setSec23] = useState([
    { key_name: "LINK", key_value: "" },
    { key_name: "BG_IMG", key_value: "" },
    { key_name: "IMG", key_value: "" },
  ]);

  const token = localStorage.getItem("admin_token");
  const [singleDropDown, setSingleDropDown] = useState([]);
  const AppUrl = "https://folcon.saharaas.com/api/files/";
  const [errorMessage, setErrorMessage] = useState("");

  const resetState = () => {
    setFormData({});
    setSelectedImageMedia([]);
    setSelectedVideoMedia([]);
    setErrorMessage("");

    setStopStatus(0);
  };

  // const handleCustomChange = (selectedOption) => {
  //   if (selectedOption.length > 2) {
  //   } else {
  //     const selectedValues = selectedOption
  //       ? selectedOption.map((option) => option.value)
  //       : [];
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       ADS: selectedValues,
  //     }));
  //   }
  // };

  const handleChange = (selectedOption) => {
    const selectedValues = selectedOption
      ? selectedOption.map((option) => option.value)
      : [];
    setFormData((prevData) => ({
      ...prevData,
      ADS: selectedValues,
    }));
  };
  const [stopStatus, setStopStatus] = useState(0);

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // {section 14 handle}

  // {  section23 handle }
  const handleImageMediaSelect = (mediaUrls) => {
    const newSelectedMedia = [...selectedImageMedia, ...mediaUrls].slice(0, 12);
    setSelectedImageMedia(newSelectedMedia);
    setIsImageGalleryOpen(false);
  };

  const handleVideoMediaSelect = (mediaUrls) => {
    setSelectedVideoMedia(
      limit === 1 ? [mediaUrls[0]] : [...selectedVideoMedia, ...mediaUrls]
    );
    setIsVideoGalleryOpen(false);
  };

  const handleDeleteMedia = (type, index) => {
    if (type === "image") {
      setSelectedImageMedia(selectedImageMedia.filter((_, i) => i !== index));
    } else if (type === "video") {
      setSelectedVideoMedia(selectedVideoMedia.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (parseInt(sectionId) === 4 && formData?.CATEGORIES !== 1) {
      setErrorMessage("Must Select Active Toggle");
      return;
    }
    if (parseInt(sectionId) === 15 && formData?.CLASSIFICATION == undefined) {
      setErrorMessage("Must Select Pre-Order");
      return;
    }
    if (parseInt(sectionId) === 19 && formData?.ADS?.length < 7) {
      setStopStatus(1);
      setErrorMessage("Please select Seven ads !");
      return;
    }
    if (parseInt(sectionId) === 21 && formData?.ADS?.length < 6) {
      // setStopStatus(1);
      setErrorMessage("Please select Six ads !");
      return;
    }
    if (parseInt(sectionId) === 20 && formData?.ADS?.length < 9) {
      setStopStatus(1);
      setErrorMessage("Please select Nine ads !");
      return;
    }
    if (parseInt(sectionId) === 22 && formData?.ADS?.length < 10) {
      setErrorMessage("Please select Ten ads !");
      return;
    }
    if (parseInt(sectionId) === 23 && formData?.ADS?.length < 6) {
      setErrorMessage("Please select Six ads !");
      return;
    }
    if (parseInt(sectionId) === 17 && formData?.SPIN !== 1) {
      setErrorMessage("Must Select Active Toggle");
      return;
    }
    if (parseInt(sectionId) === 13 && formData?.ADS?.length < 5) {
      setStopStatus(1);
      setErrorMessage("Please select five ads !");
      return;
    }
    if (parseInt(sectionId) === 11 && formData?.ADS?.length < 3) {
      setStopStatus(1);
      setErrorMessage("Please select three ads !");
      return;
    }
    if (parseInt(sectionId) === 14 && formData?.BRAND !== 1) {
      setErrorMessage("Must Select Active Toggle");
      return;
    }
    if (parseInt(sectionId) === 16 && formData?.ADS == undefined) {
      setStopStatus(1);
      setErrorMessage("Please fill all the fields !");
      return;
    } else if (parseInt(sectionId) === 16 && formData?.ADS?.length < 5) {
      setStopStatus(1);
      setErrorMessage("Please select five ads !");
      return;
    } else if (parseInt(sectionId) === 16 && formData?.ADS?.length == 5) {
      setStopStatus(0);
      setErrorMessage("");
    }
    if (parseInt(sectionId) === 5 && formData?.CLASSIFICATION == undefined) {
      // setStopStatus(1);
      setErrorMessage("Please fill all the fields !");
      return;
    }
    if (parseInt(sectionId) === 5 && formData?.CLASSIFICATION !== undefined) {
      setStopStatus(0);
      setErrorMessage("");
    }
    if (parseInt(sectionId) === 18 && formData?.ADS?.length < 6) {
      setErrorMessage("Must Select 6 ADS");
      return;
    }
    if (parseInt(sectionId) === 26 && formData?.ADS?.length < 5) {
      setErrorMessage("Must Select Active Toggle");
      return;
    }
    if (parseInt(sectionId) === 3 && formData?.ADS?.length < 3) {
      setErrorMessage("Must Select 3 Ads ");
      return;
    }
    if (parseInt(sectionId) === 8 && formData?.ADS?.length < 5) {
      setErrorMessage("Must Select 5 Ads");
      return;
    }
    if (parseInt(sectionId) === 24 && formData?.ADS?.length < 2) {
      setErrorMessage("Must Select Two Ads");
      return;
    }
    if (parseInt(sectionId) === 32 && formData?.CATEGORIES !== 1) {
      setErrorMessage("Must Select Active Toggle");
      return;
    }
    if (parseInt(sectionId) === 28 && formData?.CATEGORIES !== 1) {
      setErrorMessage("Must Select Active Toggle");
      return;
    }

    if (parseInt(sectionId) === 29 && formData?.ADS?.length < 6) {
      setErrorMessage("Must Select 6 Ads");
      return;
    }
    if (parseInt(sectionId) === 25 && selectedVideoMedia.length === 0) {
      setErrorMessage("Please select at least one video!");
      return;
    }
    if (parseInt(sectionId) === 30 && formData?.BLOGS !== 1) {
      setErrorMessage("Must Select Active Toggle");
      return;
    }
    if (parseInt(sectionId) === 31 && formData?.ADS?.length < 6) {
      setErrorMessage("Must Select 6 Ads");
      return;
    }
    console.log(formData, "formdata");
    const sectionValue = Object.keys(formData).map((key) => ({
      key_name: key === "CLASSIFICATION" ? "CLASSIFI" : key,
      key_value: Array.isArray(formData[key])
        ? formData[key].join(",")
        : String(formData[key]),
    }));

    let payload = {};
    if (parseInt(sectionId)) {
      payload = {
        page_id: parseInt(pageid),
        section_id: parseInt(sectionId),
        section_value: sectionValue.filter(
          (item) => item.key_name !== "section_id"
        ),
      };

      // if (parseInt(sectionId) === 23) {
      //   payload = {
      //     page_id: parseInt(pageid),
      //     section_id: parseInt(sectionId),
      //     section_value: sec23,
      //   };
      // }
      if (selectedImageMedia.length > 0) {
        payload.section_value.push({
          key_name: "File",
          key_value: selectedImageMedia.join(","),
        });
      }
      if (selectedVideoMedia.length > 0) {
        payload.section_value.push({
          key_name: "File",
          key_value: selectedVideoMedia.join(","),
        });
      }
    }

    if (stopStatus === 0) {
      try {
        const response = await addSection(token, payload);
        if (response.status_code === 200) {
          toast.success("Section created successfully!");
          fetchAdsDropdown();
          setSelectedVideoMedia([]);
          setSelectedImageMedia([]);
          onSuccess(true);
          setFormData({});
          setErrorMessage();
          // setSection9Ads({});

          setSelectedImageMedia([]);
          setSelectedVideoMedia([]);
        } else {
          setSelectedVideoMedia([]);
          setSelectedImageMedia([]);
          onSuccess(true);
          setFormData({});
          setErrorMessage();
        }
      } catch (error) {
        toast.error("Section creation failed. Please try again.");
      }
      onSave(formData);
      onRequestClose();
    }
  };

  useEffect(() => {
    fetchAdsDropdown();
    fetchClassificationDropdown();
    fetchAdsDropdownSingle();
    campaignDropdown();
  }, []);

  const fetchAdsDropdown = async () => {
    const response = await AdsDropDown(token);
    setAdsDropdown(
      response.data.map((option) => ({
        value: option.id,
        label: option.name.toUpperCase(),
      }))
    );
  };
  const campaignDropdown = async () => {
    const response = await CampaignDropDown(token);
    setCampaignData(
      response.data.map((option) => ({
        value: option.campaign_id,
        label: option.campaign_name.toUpperCase(),
      }))
    );
  };
  const fetchAdsDropdownSingle = async () => {
    const response = await AdsDropDown(token);
    setSingleDropDown(response.data);
  };

  const fetchClassificationDropdown = async () => {
    const classifications = await ClassificationDropDown(token);
    setSectionDropdownData(
      classifications.data.map((option) => ({
        value: option.classification_id,
        label: option.classification_name.toUpperCase(),
      }))
    );
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      section_id: sectionId,
    }));
  }, [sectionId]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Section"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: "15px",
          padding: "20px",
          maxWidth: "1500px",
          minWidth: "1500px",
          maxHeight: "700px",
          minHeight: "800px",
          overflowY: "auto",
          boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)",
          border: "2px solid #63E6BE",
        },
      }}
    >
      <div className="modal-header">
        <h2>Add Section</h2>
        <button
          onClick={() => {
            resetState();
            onRequestClose();
          }}
        >
          Close
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {[2].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <select
              id="adsDropdown2"
              name="ADS"
              value={formData.ADS || ""}
              onChange={handleUpdateChange}
              className="dropdown"
            >
              <option value="">Select an Ads</option>
              {singleDropDown.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name.toUpperCase()}
                </option>
              ))}
            </select>
            <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
            <Select
              id="ClassificationDropdown"
              name="CLASSIFI"
              value={sectionDropdownData.filter(
                (option) => option.value === formData.CLASSIFICATION
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CLASSIFICATION: selectedOption.value,
                }))
              }
              options={sectionDropdownData}
            />
            <label>Background Image </label>
            <button
              type="button"
              className="btn btn-secondary mb-3"
              onClick={() => setIsImageGalleryOpen(true)}
            >
              Image Gallery
            </button>
            <label htmlFor="titleInput">Link</label>
            <input
              id="titleInput1"
              type="text"
              name="LINK"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter Link here"
            />
          </div>
        )}
        {[1].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={handleChange}
              options={adsDropdown}
              isMulti
            />
            <label>Background Image </label>
            <button
              type="button"
              className="btn btn-secondary mb-3"
              onClick={() => setIsImageGalleryOpen(true)}
            >
              Image Gallery
            </button>
          </div>
        )}
        {[19].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              // onChange={handleChange}
              onChange={(selectedOption) => {
                if (selectedOption.length <= 7) {
                  handleChange(selectedOption);
                } else {
                  setErrorMessage("You can select a maximum of 7 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}
        {[20].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={(selectedOption) => {
                if (selectedOption.length <= 9) {
                  handleChange(selectedOption);
                } else {
                  setErrorMessage("You can select a maximum of 9 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}
        {/* {section 9} */}
        {[9].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title 1</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title 1 here"
            />
            <label htmlFor="titleInput">Title 2</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title 2 here"
            />
            <label htmlFor="titleInput">Description</label>
            <input
              id="titleInput1"
              type="text"
              name="DESCRIPTION"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter description here"
            />
            <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
            <Select
              id="ClassificationDropdown"
              name="CLASSIFI"
              value={sectionDropdownData.filter(
                (option) => option.value === formData.CLASSIFICATION
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CLASSIFICATION: selectedOption.value,
                }))
              }
              options={sectionDropdownData}
            />
          </div>
        )}
        {[12].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <div className="input-container form-group">
              <label htmlFor="titleInput1">Title</label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title "
              />
            </div>
            {/* Classification */}
            <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
            <Select
              id="ClassificationDropdown"
              name="CLASSIFI"
              value={sectionDropdownData.filter(
                (option) => option.value === formData.CLASSIFICATION
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CLASSIFICATION: selectedOption.value,
                }))
              }
              options={sectionDropdownData}
            />
          </div>
        )}
        {[4].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            {/* {toggle} */}
            <div className="dropdown-container">
              <label htmlFor="adsDropdown2">Categories Toggele</label>
              <ToggleSwitch
                checked={formData.CATEGORIES === 1}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    CATEGORIES: e.target.checked ? 1 : 0,
                  }))
                }
              />
            </div>
          </div>
        )}
        {[5].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <div className="input-container form-group">
              <label htmlFor="titleInput">Title: </label>
              <input
                id="titleInput"
                type="text"
                name="TITLE"
                value={formData.TITLE}
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title"
              />
            </div>

            <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
            <Select
              id="ClassificationDropdown"
              name="CLASSIFI"
              value={sectionDropdownData.filter(
                (option) => option.value === formData.CLASSIFICATION
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CLASSIFICATION: selectedOption.value,
                }))
              }
              options={sectionDropdownData}
            />
          </div>
        )}
        {[13].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <div className="input-container form-group">
              <label htmlFor="titleInput">Title: </label>
              <input
                id="titleInput"
                type="text"
                name="TITLE"
                value={formData.TITLE}
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title"
              />
            </div>
            <div className="input-container form-group">
              <label htmlFor="linkInput">Link: </label>
              <input
                id="linkInput"
                type="text"
                name="LINK"
                value={formData.LINK}
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title"
              />
            </div>
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={(selectedOption) => {
                if (selectedOption.length <= 5) {
                  handleChange(selectedOption);
                  setStopStatus(0);
                  setErrorMessage("");
                } else {
                  setErrorMessage("You can select a maximum of 5 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}
        {/* section 15 */}
        {[15].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <div className="input-container form-group">
              <label htmlFor="titleInput">Title: </label>
              <input
                id="titleInput"
                type="text"
                name="TITLE"
                value={formData.TITLE}
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title"
              />
            </div>
            <label htmlFor="titleInput">Link</label>
            <input
              id="titleInput1"
              type="text"
              name="LINK"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter Link here"
            />
            <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
            <Select
              id="ClassificationDropdown"
              name="CLASSIFI"
              value={sectionDropdownData.filter(
                (option) => option.value === formData.CLASSIFICATION
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CLASSIFICATION: selectedOption.value,
                }))
              }
              options={sectionDropdownData}
            />
          </div>
        )}
        {[8].includes(parseInt(sectionId)) && (
          <div className="conditional-container">
            <div className="dropdown-container">
              <div className="input-container form-group">
                <label htmlFor="titleInput1">Title 1: </label>
                <input
                  id="titleInput1"
                  type="text"
                  name="TITLE_1"
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title 1"
                />
              </div>
              <label htmlFor="adsDropdown">Ads</label>
              <Select
                id="adsDropdown"
                name="ADS"
                value={
                  formData.ADS
                    ? adsDropdown.filter((option) =>
                      formData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 5) {
                    handleChange(selectedOption);
                    setStopStatus(0);
                    setErrorMessage("");
                  } else {
                    setErrorMessage("You can select a maximum of 5 ads.");
                  }
                }}
                options={adsDropdown}
                isMulti
              />
            </div>
            <div className="input-container form-group">
              <label htmlFor="titleInput1">Title 2:</label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE_2"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title 2"
              />
            </div>
          </div>
        )}
        {[18].includes(parseInt(sectionId)) && (
          <div className="conditional-container">
            <div className="dropdown-container">
              <div className="input-container form-group">
                <label htmlFor="titleInput1">Title 1: </label>
                <input
                  id="titleInput1"
                  type="text"
                  name="TITLE_1"
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title 1"
                />
              </div>
              <label htmlFor="adsDropdown">Ads</label>
              <Select
                id="adsDropdown"
                name="ADS"
                value={
                  formData.ADS
                    ? adsDropdown.filter((option) =>
                      formData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 6) {
                    handleChange(selectedOption);
                    setStopStatus(0);
                    setErrorMessage("");
                  } else {
                    setErrorMessage("You can select a maximum of 6 ads.");
                  }
                }}
                options={adsDropdown}
                isMulti
              />
            </div>
          </div>
        )}
        {[16].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Link</label>
            <input
              id="titleInput1"
              type="text"
              name="LINK"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter link here"
            />
            <div>
              <label htmlFor="adsDropdown">Ads</label>
              <Select
                id="adsDropdown"
                name="ADS"
                value={
                  formData.ADS
                    ? adsDropdown.filter((option) =>
                      formData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 5) {
                    handleChange(selectedOption);
                    setStopStatus(0);
                    setErrorMessage("");
                  } else {
                    setErrorMessage("You can select a maximum of 5 ads.");
                  }
                }}
                options={adsDropdown}
                isMulti
              />
            </div>
          </div>
        )}
        {[7].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
            <Select
              id="ClassificationDropdown"
              name="CLASSIFI"
              value={sectionDropdownData.filter(
                (option) => option.value === formData.CLASSIFICATION
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CLASSIFICATION: selectedOption.value,
                }))
              }
              options={sectionDropdownData}
            />

            <label htmlFor="adsDropdown">Ads</label>
            <select
              id="adsDropdown2"
              name="ADS"
              value={formData.ADS || ""}
              onChange={handleUpdateChange}
              className="dropdown"
            >
              <option value="">Select an Ads</option>
              {singleDropDown.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name.toUpperCase()}
                </option>
              ))}
            </select>

            <label htmlFor="titleInput">Link</label>
            <input
              id="titleInput1"
              type="text"
              name="LINK"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter Link here"
            />
          </div>
        )}
        {[6].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput1">Title 1</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput2">Title 2</label>
            <input
              id="titleInput2"
              type="text"
              name="TITLE_2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="Campaign">CAMPAIGN</label>
            <Select
              id="CampaignDropdown"
              name="CAMP"
              value={campaignData.filter(
                (option) => option.value === formData.CAMP
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CAMP: selectedOption.value,
                }))
              }
              options={campaignData}
            />
          </div>
        )}
        {[10].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>

            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
            <Select
              id="ClassificationDropdown"
              name="CLASSIFI"
              value={sectionDropdownData.filter(
                (option) => option.value === formData.CLASSIFICATION
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CLASSIFICATION: selectedOption.value,
                }))
              }
              options={sectionDropdownData}
            />
          </div>
        )}
        {[11].includes(parseInt(sectionId)) && (
          <div className="conditional-container">
            <div className="dropdown-container">
              <div className="input-container form-group">
                <label htmlFor="titleInput1">Title 1: </label>
                <input
                  id="titleInput1"
                  type="text"
                  name="TITLE_1"
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title 1"
                />
              </div>
              <label htmlFor="adsDropdown">Ads</label>
              <Select
                id="adsDropdown"
                name="ADS"
                value={
                  formData.ADS
                    ? adsDropdown.filter((option) =>
                      formData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 3) {
                    handleChange(selectedOption);
                    setStopStatus(0);
                    setErrorMessage("");
                  } else {
                    setErrorMessage("You can select a maximum of 3 ads.");
                  }
                }}
                options={adsDropdown}
                isMulti
              />
            </div>
          </div>
        )}
        {/* section 14 */}
        {[14].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            {/* {toggle} */}
            <div className="dropdown-container">
              <label htmlFor="adsDropdown2">Brand Toggle</label>
              <ToggleSwitch
                checked={formData.BRAND === 1}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    BRAND: e.target.checked ? 1 : 0,
                  }))
                }
              />
            </div>
          </div>
        )}

        {[25].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            {limit === 0 ? (
              <>
                {/* <button
                type="button"
                className="btn btn-secondary mb-3"
                onClick={() => setIsImageGalleryOpen(true)}
              >
                Image Gallery
              </button> */}
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() => setIsVideoGalleryOpen(true)}
                >
                  Video Gallery
                </button>
              </>
            ) : (
              <>
                {/* <button
                type="button"
                className="btn btn-secondary mb-3"
                onClick={() =>
                  setIsImageGalleryOpen(selectedImageMedia.length === 0)
                }
                disabled={selectedImageMedia.length > 0}
              >
                Image Gallery
              </button> */}
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() =>
                    setIsVideoGalleryOpen(selectedVideoMedia.length === 0)
                  }
                  disabled={selectedVideoMedia.length > 0}
                >
                  Video Gallery
                </button>
              </>
            )}
            <label htmlFor="titleInput">Title:1</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_1"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Title:2</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Title:3</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_3"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Title:4</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_4"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
          </div>
        )}
        {[21].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              // onChange={handleChange}
              onChange={(selectedOption) => {
                if (selectedOption.length <= 6) {
                  handleChange(selectedOption);
                } else {
                  setErrorMessage("You can select a maximum of 6 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}
        {[22].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title </label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <div className="dropdown-container">
              <label htmlFor="adsDropdown">Ads</label>
              <Select
                id="adsDropdown"
                name="ADS"
                value={
                  formData.ADS
                    ? adsDropdown.filter((option) =>
                      formData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 10) {
                    handleChange(selectedOption);
                    setStopStatus(0);
                    setErrorMessage("");
                  } else {
                    setErrorMessage("You can select a maximum of 10 ads.");
                  }
                }}
                options={adsDropdown}
                isMulti
              />
            </div>
          </div>
        )}
        {[23].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title </label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <div className="dropdown-container">
              <label htmlFor="adsDropdown">Ads</label>
              <Select
                id="adsDropdown"
                name="ADS"
                value={
                  formData.ADS
                    ? adsDropdown.filter((option) =>
                      formData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 6) {
                    handleChange(selectedOption);
                    setStopStatus(0);
                    setErrorMessage("");
                  } else {
                    setErrorMessage("You can select a maximum of 6 ads.");
                  }
                }}
                options={adsDropdown}
                isMulti
              />
            </div>
          </div>
        )}

        {/* {SECTION 17} */}
        {[17].includes(parseInt(sectionId)) && (
          <div>
            <div className="input-container form-group">
              <label htmlFor="titleInput">Title</label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter Title "
              />
            </div>
            {/* {toggle} */}
            <div className="dropdown-container">
              <label htmlFor="adsDropdown2">Active Blogs:</label>
              <ToggleSwitch
                checked={formData.SPIN === 1}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    SPIN: e.target.checked ? 1 : 0,
                  }))
                }
              />
            </div>
          </div>
        )}
        {/* section 26 */}
        {[26].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <div className="input-container form-group">
              <label htmlFor="titleInput">Title: </label>
              <input
                id="titleInput"
                type="text"
                name="TITLE"
                value={formData.TITLE}
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title"
              />
            </div>
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={(selectedOption) => {
                if (selectedOption.length <= 5) {
                  handleChange(selectedOption);
                  setStopStatus(0);
                  setErrorMessage("");
                } else {
                  setErrorMessage("You can select a maximum of 5 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}
        {[3].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <div className="input-container form-group">
              <label htmlFor="titleInput">Title</label>
              <input
                id="titleInput"
                type="text"
                name="TITLE"
                value={formData.TITLE}
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title"
              />
            </div>
            <div className="input-container form-group">
              <label htmlFor="titleInput">Description</label>
              <input
                id="titleInput"
                type="text"
                name="DESC"
                value={formData.DESC}
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title"
              />
            </div>
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={(selectedOption) => {
                if (selectedOption.length <= 3) {
                  handleChange(selectedOption);
                  setStopStatus(0);
                  setErrorMessage("");
                } else {
                  setErrorMessage("You can select a maximum of 3 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}
        {[32].includes(parseInt(sectionId)) && (
          <div>
            <div className="input-container form-group">
              <label htmlFor="titleInput">Title:1</label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title here"
              />
            </div>
            <div className="input-container form-group">
              <label htmlFor="titleInput">Title:2</label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE_1"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title here"
              />
            </div>
            <div className="input-container form-group">
              <label htmlFor="titleInput">Color</label>
              <input
                id="titleInput1"
                type="text"
                name="BGCOLOR"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title here"
              />
            </div>
            <div className="dropdown-container">
              <label htmlFor="adsDropdown2">Categories Toggele</label>
              <ToggleSwitch
                checked={formData.CATEGORIES === 1}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    CATEGORIES: e.target.checked ? 1 : 0,
                  }))
                }
              />
            </div>
          </div>
        )}
        {[28].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            {limit === 0 ? (
              <>
                {/* <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => setIsImageGalleryOpen(true)}
                  >
                    Image Gallery
                  </button> */}
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() => setIsVideoGalleryOpen(true)}
                >
                  Video Gallery
                </button>
              </>
            ) : (
              <>
                {/* <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      setIsImageGalleryOpen(selectedImageMedia.length === 0)
                    }
                    disabled={selectedImageMedia.length > 0}
                  >
                    Image Gallery
                  </button> */}
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() =>
                    setIsVideoGalleryOpen(selectedVideoMedia.length === 0)
                  }
                  disabled={selectedVideoMedia.length > 0}
                >
                  Video Gallery
                </button>
              </>
            )}
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <div className="dropdown-container">
              <label htmlFor="adsDropdown2">Active Blogs:</label>
              <ToggleSwitch
                checked={formData.CATEGORIES === 1}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    CATEGORIES: e.target.checked ? 1 : 0,
                  }))
                }
              />
            </div>
          </div>
        )}
        {[29].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput"
              type="text"
              name="TITLE"
              value={formData.TITLE}
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title"
            />
            <label htmlFor="titleInput">Background - Color</label>
            <input
              id="titleInput"
              type="text"
              name="BGCOLOR"
              value={formData.BGCOLOR}
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter Background color Code "
            />
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={(selectedOption) => {
                if (selectedOption.length <= 6) {
                  handleChange(selectedOption);
                  setStopStatus(0);
                  setErrorMessage("");
                } else {
                  setErrorMessage("You can select a maximum of 6 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}
        {[24].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Description</label>
            <input
              id="titleInput1"
              type="text"
              name="DESC"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput">Link</label>
            <input
              id="titleInput1"
              type="text"
              name="LINK"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
            <Select
              id="ClassificationDropdown"
              name="CLASSIFI"
              value={sectionDropdownData.filter(
                (option) => option.value === formData.CLASSIFICATION
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CLASSIFICATION: selectedOption.value,
                }))
              }
              options={sectionDropdownData}
            />
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={(selectedOption) => {
                if (selectedOption.length <= 2) {
                  handleChange(selectedOption);
                  setStopStatus(0);
                  setErrorMessage("");
                } else {
                  setErrorMessage("You can select a maximum of 2 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />
          </div>
        )}
        {[27].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            {limit === 0 ? (
              <>
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() => setIsImageGalleryOpen(true)}
                >
                  Image Gallery
                </button>
                {/* <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() => setIsVideoGalleryOpen(true)}
                >
                  Video Gallery
                </button> */}
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() =>
                    setIsImageGalleryOpen(selectedImageMedia.length === 0)
                  }
                  disabled={selectedImageMedia.length > 0}
                >
                  Image Gallery
                </button>
                {/* <button
                  type="button"
                  className="btn btn-secondary mb-3"
                  onClick={() =>
                    setIsVideoGalleryOpen(selectedVideoMedia.length === 0)
                  }
                  disabled={selectedVideoMedia.length > 0}
                >
                  Video Gallery
                </button> */}
              </>
            )}
            <label htmlFor="titleInput">Title:1</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <select
              id="adsDropdown2"
              name="ADS"
              value={formData.ADS || ""}
              onChange={handleUpdateChange}
              className="dropdown"
            >
              <option value="">Select an Ads</option>
              {singleDropDown.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name.toUpperCase()}
                </option>
              ))}
            </select>
            <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
            <Select
              id="ClassificationDropdown"
              name="CLASSIFI"
              value={sectionDropdownData.filter(
                (option) => option.value === formData.CLASSIFICATION
              )}
              onChange={(selectedOption) =>
                setFormData((prevData) => ({
                  ...prevData,
                  CLASSIFICATION: selectedOption.value,
                }))
              }
              options={sectionDropdownData}
            />
          </div>
        )}
        {[30].includes(parseInt(sectionId)) && (
          <div className="dropdown-container">
            <div className="dropdown-container">
              <label htmlFor="titleInput">Title</label>
              <input
                id="titleInput1"
                type="text"
                name="TITLE"
                onChange={handleUpdateChange}
                className="form-control"
                placeholder="Enter title here"
              />
              {/* {toggle} */}
              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Brand Toggle</label>
                <ToggleSwitch
                  checked={formData.BLOGS === 1}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      BLOGS: e.target.checked ? 1 : 0,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        )}
        {[31].includes(parseInt(sectionId)) && (
          <div className="dropdown-container ">
            <label htmlFor="titleInput">Title</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="titleInput ">Title 2:</label>
            <input
              id="titleInput1"
              type="text"
              name="TITLE_2"
              onChange={handleUpdateChange}
              className="form-control"
              placeholder="Enter title here"
            />
            <label htmlFor="adsDropdown">Ads</label>
            <Select
              id="adsDropdown"
              name="ADS"
              value={
                formData.ADS
                  ? adsDropdown.filter((option) =>
                    formData.ADS.includes(option.value)
                  )
                  : []
              }
              onChange={(selectedOption) => {
                if (selectedOption.length <= 6) {
                  handleChange(selectedOption);
                  setStopStatus(0);
                  setErrorMessage("");
                } else {
                  setErrorMessage("You can select a maximum of 6 ads.");
                }
              }}
              options={adsDropdown}
              isMulti
            />

          </div>
        )}
        {/* ************************************************************************************************************************************* */}
        <div className="media-preview-container">
          {selectedImageMedia.length > 0 && (
            <div className="media-preview">
              <h3>Selected Images</h3>
              <div className="media-items">
                {selectedImageMedia.map((mediaUrl, index) => (
                  <div key={index} className="media-item">
                    <img
                      src={AppUrl + mediaUrl}
                      alt="Selected"
                      width={"250px"}
                    />
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDeleteMedia("image", index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {selectedVideoMedia.length > 0 && (
            <div className="media-preview">
              <h3>Selected Videos</h3>
              <div className="media-items">
                {selectedVideoMedia.map((mediaUrl, index) => (
                  <div key={index} className="media-item">
                    <video
                      src={AppUrl + mediaUrl}
                      controls
                      width={"250px"}
                      height={"200px"}
                    />
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => handleDeleteMedia("video", index)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <button type="submit" className="save-button">
          Save
        </button>
      </form>
      <ImageGallery
        isOpen={isImageGalleryOpen}
        onRequestClose={() => setIsImageGalleryOpen(false)}
        onMediaSelect={handleImageMediaSelect}
        selectedMedia={selectedImageMedia}
        setSelectedMedia={setSelectedImageMedia}
        limit={3}
        sectionId={sectionId}
      />
      <VideoGallery
        isOpen={isVideoGalleryOpen}
        onRequestClose={() => setIsVideoGalleryOpen(false)}
        onMediaSelect={handleVideoMediaSelect}
        selectedMedia={selectedVideoMedia}
        setSelectedMedia={setSelectedVideoMedia}
        limit={limit}
      />
    </Modal>
  );
};

export default SectionCreate;

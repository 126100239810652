import React, { useEffect, useState } from "react";
// import UploadImage from '../../../assets/images/upload.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import UploadImage from '../../../assets/images/defimg.jpeg';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import { MediaList } from "../../../service/apiService";
import { useNavigate } from "react-router-dom";


const VariantsCreate = () => {
  const [VariantsformData, setVariantsFormData] = useState({
    name: '',
    slug: '',
    is_active: 1
  });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    slug: ""
  });
  var navigate = useNavigate();
  // const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const [selectedImages, setSelectedImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const AppUrl = "https://folcon.saharaas.com/api/files/";
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [varientImage, setVarientImage] = useState(null);
  const [payloadImageUrl, setpayloadImageUrl] = useState(null);
  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter(i => i !== index));
      }

    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setVarientImage(imageUrl[index]);
      setpayloadImageUrl('files/' + imageUrl[index]);
      closeModal();
    }
  };

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      // if(response.length > 0){
      response.then((response) => {
        const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
        setImageUrl(imageUrls);
      });
      //   }
    }
  }, [token]);



  const handleChange = (e) => {
    const { id, value } = e.target;
    setVariantsFormData({
      ...VariantsformData,
      [id]: value,
      slug: generateSlug(value)
    });
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setVariantsFormData({
      ...VariantsformData,
      is_active: activeValue
    });
  };

  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, '-');
  };

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setSelectedFile(file);

  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setPreviewImage(reader.result);
  //   };
  //   reader.readAsDataURL(file);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');
    const errors = {};
    if (!VariantsformData.name) {
      errors.name = "Name is required";
    }

    setValidationErrors(errors);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {
      if (!VariantsformData.name || !VariantsformData.slug) {
        toast.error('Please Fill The Form Data');
        return;
      }
      const createPayload = {
        ...VariantsformData,
        image_path: payloadImageUrl,
        name: VariantsformData.name,
      };

      const createResponse = await axios.post('https://folcon.saharaas.com/admin/create-variant-label', createPayload, config);

      if (createResponse.data.status === "success") {
        setPreviewImage(null);
        toast.success('Variant created successfully!');
        navigate('/admin/variants');
        setVariantsFormData({
          name: '',
          slug: '',
          is_active: 1
        });
      } else {
        toast.error('Variant creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating Variant:', error);
      toast.error('Variant creation failed. Please try again.');
    }
  };


  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Variants Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Variants</li>
            <li className="breadcrumb-item active">Create</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-10">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-4">
                      <input id="name" type="text" value={VariantsformData.name} placeholder="Type here" onChange={handleChange} className="form-control" />
                      {validationErrors.name && <div className="text-danger">{validationErrors.name}</div>}

                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="slug" className="col-sm-2 col-form-label">Slug</label>
                    <div className="col-sm-4">
                      <input id="slug" type="text" value={VariantsformData.slug} placeholder="Type here" onChange={handleChange} className="form-control" readOnly />
                    </div>
                  </div>
                  <div className="md-4">
                    <label htmlFor="toggle" className="col-sm-2 col-form-label">Status</label>
                    <div className="col-sm-4">
                      <input className="form-check-input" type="checkbox" id="toggle" checked={VariantsformData.is_active === 1} onChange={handleToggleChange} />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label>Preview</label>
                    <br />
                    {previewImage ? (
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", width: "200px", height: "200px" }} />

                        <button
                          type="button"
                          onClick={handleDeletePreview}
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            border: 'none',
                            cursor: 'pointer'
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    ) : (
                      <img src={UploadImage} alt="Alt" />
                    )}
                    <br></br>
                    <button type="button" className="btn btn-primary mt-2 btn-sm btn-block" onClick={openModal}>
                      Select Image
                    </button>
                  </div>


                  {/* <div className="md-4">
                            <h4>Preview</h4>
                            <div className="input-upload">
                                {previewImage ? (
                                
                                <img src={previewImage} alt="Preview" style={{ maxWidth: '100%' }} />
                            ) : (
                            <img src={UploadImage} alt="Alt" />
                           
                            )}
                            <div className="col-sm-4">
                            <input className="form-control" name='image_path' id='image_path' type="file" onChange={handleImageChange} />
                            </div>
                        </div>
                    </div> */}

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <SingleImageSelect
            isOpen={modalIsOpen}
            handleClose={closeModal}
            handleImageClick={handleImageClick}
            selectedImages={selectedImages}
            imageUrl={imageUrl}
            token={token}
            setImageUrl={setImageUrl}
          />
        </div>

      </section>
    </main>

  )



}


export default VariantsCreate
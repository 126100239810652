import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faPenToSquare,
  faTrashCanArrowUp,
  faToggleOn,
  faToggleOff,
} from "@fortawesome/free-solid-svg-icons";
import {
  deleteSection,
  pageSectionList,
  saveKanbanChangesAPICall,
} from "../../../../service/apiService";
import SectionEditModel from "../SectionEdit/SectionEditModel";
import { toast } from "react-toastify";
import Modal from "react-modal";
import SECTION_1 from "../../../../assets/images/SectionImages/section_1.png";
import SECTION_2 from "../../../../assets/images/SectionImages/section_2.png";
import SECTION_3 from "../../../../assets/images/SectionImages/section_3.png";
import SECTION_4 from "../../../../assets/images/SectionImages/section_4.png";
import SECTION_5 from "../../../../assets/images/SectionImages/section_5.png";
import SECTION_6 from "../../../../assets/images/SectionImages/section_6.png";
import SECTION_7 from "../../../../assets/images/SectionImages/section_7.png";
import SECTION_8 from "../../../../assets/images/SectionImages/section_8.png";
import SECTION_9 from "../../../../assets/images/SectionImages/section_9.png";
import SECTION_10 from "../../../../assets/images/SectionImages/section_10.png";
import SECTION_11 from "../../../../assets/images/SectionImages/section_11.png";
import SECTION_12 from "../../../../assets/images/SectionImages/section_12.png";
import SECTION_13 from "../../../../assets/images/SectionImages/section_13.png";
import SECTION_14 from "../../../../assets/images/SectionImages/section_14.png";
import SECTION_15 from "../../../../assets/images/SectionImages/section_15.png";
import SECTION_16 from "../../../../assets/images/SectionImages/section_16.png";
import SECTION_17 from "../../../../assets/images/SectionImages/section_17.png";
import SECTION_18 from "../../../../assets/images/SectionImages/section_18.png";
import SECTION_19 from "../../../../assets/images/SectionImages/section_19.png";
import SECTION_20 from '../../../../assets/images/SectionImages/section_20.png';
import SECTION_21 from '../../../../assets/images/SectionImages/section_21.png';
import SECTION_22 from '../../../../assets/images/SectionImages/section_22.png';
import SECTION_23 from '../../../../assets/images/SectionImages/section_23.png';
import SECTION_24 from "../../../../assets/images/SectionImages/section_24.png";
import SECTION_25 from '../../../../assets/images/SectionImages/section_25.png';
import SECTION_26 from '../../../../assets/images/SectionImages/section_26.png';
import SECTION_27 from '../../../../assets/images/SectionImages/section_27.png';
import SECTION_28 from '../../../../assets/images/SectionImages/section_28.png';
import SECTION_29 from '../../../../assets/images/SectionImages/section_29.png';
import SECTION_30 from '../../../../assets/images/SectionImages/section_30.png';
import SECTION_31 from "../../../../assets/images/SectionImages/section_31.png";
import SECTION_32 from '../../../../assets/images/SectionImages/section_32.png';



import Swal from "sweetalert2";


const ItemTypes = {
  SECTION: "section",
};

const token = localStorage.getItem("admin_token");

const Section = ({ section, index, moveSection, setRefreshData }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(section.isActive); const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const ImageList = [
    { key_name: "SECTION_1", key_value: SECTION_1 },
    { key_name: "SECTION_2", key_value: SECTION_2 },
    { key_name: "SECTION_3", key_value: SECTION_3 },
    { key_name: "SECTION_4", key_value: SECTION_4 },
    { key_name: "SECTION_5", key_value: SECTION_5 },
    { key_name: "SECTION_6", key_value: SECTION_6 },
    { key_name: "SECTION_7", key_value: SECTION_7 },
    { key_name: "SECTION_8", key_value: SECTION_8 },
    { key_name: "SECTION_9", key_value: SECTION_9 },
    { key_name: "SECTION_10", key_value: SECTION_10 },
    { key_name: "SECTION_11", key_value: SECTION_11 },
    { key_name: "SECTION_12", key_value: SECTION_12 },
    { key_name: "SECTION_13", key_value: SECTION_13 },
    { key_name: "SECTION_14", key_value: SECTION_14 },
    { key_name: "SECTION_15", key_value: SECTION_15 },
    { key_name: "SECTION_16", key_value: SECTION_16 },
    { key_name: "SECTION_17", key_value: SECTION_17 },
    { key_name: "SECTION_18", key_value: SECTION_18 },
    { key_name: "SECTION_19", key_value: SECTION_19 },
    { key_name: "SECTION_20", key_value: SECTION_20 },
    { key_name: "SECTION_21", key_value: SECTION_21 },
    { key_name: "SECTION_22", key_value: SECTION_22 },
    { key_name: "SECTION_23", key_value: SECTION_23 },
    { key_name: "SECTION_24", key_value: SECTION_24 },
    { key_name: "SECTION_25", key_value: SECTION_25 },
    { key_name: "SECTION_26", key_value: SECTION_26 },
    { key_name: "SECTION_27", key_value: SECTION_27 },
    { key_name: "SECTION_28", key_value: SECTION_28 },
    { key_name: "SECTION_29", key_value: SECTION_29 },
    { key_name: "SECTION_30", key_value: SECTION_30 },
    { key_name: "SECTION_31", key_value: SECTION_31 },
    { key_name: "SECTION_32", key_value: SECTION_32 },

    
  ];
  const findImageUrl = (keyName) => {
    const found = ImageList.find((image) => image.key_name === keyName);
    return found ? found.key_value : null;
  };
  console.log(ImageList[SECTION_19], "w");
  const [{ isDragging }, ref] = useDrag({
    type: ItemTypes.SECTION,
    item: { index, id: section.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  //edit icon
  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleView = () => {
    const imageSrc = findImageUrl(section.title);
    setImageUrl(imageSrc);
    setIsViewModalOpen(true);
  };


  function extractNumber(input) {
    const match = input.match(/\d+/);
    return match ? Number(match[0], 10) : null;
  }
  const Section_id = extractNumber(section.id);
  const pageid = Number(localStorage.getItem("pageid"));


  const handleDelete = async () => {
    const result = await Swal.fire({
      title: 'Want to delete the Section ?',
      text: "This action will delete the section permanently !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes ,delete it!',
    });

    if (result.isConfirmed) {
      await Swal.fire({
        title: 'Confirm Delete',
        text: "Are you really sure you want to delete this section?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
      }).then(async (secondResult) => {
        if (secondResult.isConfirmed) {
          const payload = {
            page_id: parseInt(localStorage.getItem("pageid")),
            section_id: parseInt(section.id.match(/\d+/)[0]),
            section_group: parseInt(section.section_group),
          };

          try {
            const response = await deleteSection(token, payload);
            if (response.status_code === 200) {
              await Swal.fire(
                'Deleted!',
                'Your section has been deleted.',
                'success'
              );
              setRefreshData(prev => prev + 1);
            } else {
              toast.error("Section deletion failed. Please try again.");
            }
          } catch (error) {
            toast.error("Section deletion failed. Please try again.");
          }
        }
      });
    }
  };

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  return (
    <div
      ref={ref}
      style={{
        padding: 10,
        border: "1px solid #ccc",
        borderRadius: 5,
        marginBottom: 10,
        backgroundColor: isDragging ? "#d3d3d3" : "#fff",
        opacity: isDragging ? 0.5 : 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "move",
      }}
    >
      <span style={{ flex: 1, margin: 0 }}>{section.title}</span>
      <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
        <button className="button-bg" onClick={handleView}>
          <FontAwesomeIcon icon={faCircleInfo} title="View" />
        </button>
        <button className="button-bg" onClick={handleEdit}>
          <FontAwesomeIcon icon={faPenToSquare} title="Edit" />
        </button>
        <button className="button-bg" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrashCanArrowUp} title="Delete" />
        </button>
        <button className="button-bg" onClick={toggleActive}>
          <FontAwesomeIcon
            icon={isActive ? faToggleOn : faToggleOff}
            title={isActive ? "Deactivate" : "Activate"}
          />
          <span> {isActive ? "Active" : "Inactive"}</span>
        </button>
      </div>
      {/* Edit Modal */}
      <SectionEditModel
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        sectionId={section.id}
        sectionGroupId={section.section_group}
        // section_mapId={section.}
        onSuccess={isActive}
      />
      <Modal
        isOpen={isViewModalOpen}
        onRequestClose={() => setIsViewModalOpen(false)}
        contentLabel="View Section Image"
      >
        <div
          style={{ position: "relative", padding: "20px", textAlign: "center" }}
        >
          <button
            onClick={() => setIsViewModalOpen(false)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              cursor: "pointer",
            }}
          >
            &times;
          </button>
          {imageUrl && (
            <img
              src={imageUrl}
              alt={section.title}
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

const KanbanBoard = ({ success }) => {
  const [sections, setSections] = useState([]);
  const [refreshData, setRefreshData] = useState(0);


  const moveSection = (dragIndex, hoverIndex) => {
    const draggedSection = sections[dragIndex];
    const newSections = [...sections];
    newSections.splice(dragIndex, 1);
    newSections.splice(hoverIndex, 0, draggedSection);
    newSections.forEach((section, index) => {
      section.index = index;
    });

    setSections(newSections);
  };
  // *********************************
  useEffect(() => {
    const fetchSections = async () => {
      const pageid = localStorage.getItem("pageid");
      const response = await pageSectionList(token, pageid);
      if (response?.data) {
        const transformedData = transformData(response.data);
        setSections(transformedData);
      } else {
        setSections([]);
      }
    };
    fetchSections();
  }, [refreshData, success]);

  const transformData = (data) => {
    return data.map((section, index) => ({
      id: `section${section.section_id}_${index}`,
      title: section.section_name.toUpperCase(),
      isActive: section.is_active,
      section_group: section.section_group,
    }));
  };

  const saveKanbanChanges = async () => {
    const updatedKanbanSections = sections.map((item, index) => ({
      section_group: item.section_group,
      place_order: index + 1,
    }));
    const payload = {
      page_id: 1,
      section_place: updatedKanbanSections,
    };
    try {
      const response = await saveKanbanChangesAPICall(token, payload);
      const status = await response.status_code;
      console.log("status", status);
      if (status === 200) {
        toast.success("Section place changed successfully !");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 0",
          borderBottom: "2px solid #ccc",
        }}
      >
        <strong style={{ flex: 1 }}>Section Name</strong>
        <div style={{ display: "flex", gap: "7px" }}>
          <strong>Edit |</strong>
          <strong>View |</strong>
          <strong>Delete |</strong>
          <strong>Status </strong>
        </div>
      </div>
      {sections.map((section, index) => (
        <SectionWrapper
          key={section.id}
          index={index}
          section={section}
          moveSection={moveSection}
          setRefreshData={setRefreshData}
        />
      ))}
      <button onClick={saveKanbanChanges}>Save Changes</button>
    </div>
  );
};

const SectionWrapper = ({ section, index, moveSection, setRefreshData }) => {
  const [, drop] = useDrop({
    accept: ItemTypes.SECTION,
    hover: (item) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveSection(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  return (
    <div ref={drop}>
      <Section section={section} index={index} moveSection={moveSection} setRefreshData={setRefreshData} />
    </div>
  );
};

export default KanbanBoard;

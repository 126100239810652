import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import Modal from 'react-modal';
import UploadImage from '../../../assets/images/upload.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import SingleImageSelect from '../../../components/Media/SingleImageSelect';
import { MediaList } from '../../../service/apiService';

import { useNavigate } from 'react-router-dom';


import noImage from "../../../assets/images/noimage.jpg";






const CouponList = () => {
    const navigate = useNavigate();

    const [CouponList, SetCouponList] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [CouponFormData, setCouponFormData] = useState({
        name: '',
        coupon_code: '',
        description: '',
        expire_date: '',
        percentage: '',
        max_time_use: '',
        min_purchase_amount: '',
        is_active: 1
    });

    const [selectedCouponId, setSelectedCouponId] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [imag, setImag] = useState(null);
    const [showTrashIcon, setShowTrashIcon] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [imageUrl, setImageUrl] = useState([]);
    const token = localStorage.getItem("admin_token");
    const [couponImage, setCouponImage] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const AppUrl = "https://folcon.saharaas.com/api/files/";
    const [deletedImage, setDeletedImage] = useState(false);




    // const handleDeletePreview = () => {
    //   setPreviewImage(null);
    //   setSelectedImages([]);
    // };

    const handleDeletePreview = () => {
        setPreviewImage(null);
        setSelectedImages([]);
        setCouponImage(null);
        setDeletedImage(true);
    };

    const handleImageClick = (index) => {
        if (selectedImages.includes(index)) {
            if (selectedImages.length > 0) {
                setSelectedImages(selectedImages.filter(i => i !== index));
            }

        } else {
            setSelectedImages([index]);
            setPreviewImage(AppUrl + imageUrl[index]);
            setCouponImage(imageUrl[index]);
            closeMediaModal();
        }
    };


    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;

    const pageCount = Math.ceil(CouponList.length / itemsPerPage);
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };
    const currentCouponList = CouponList.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)

    useEffect(() => {
        fetchData();

        if (token) {
            const response = MediaList(token);
            // if(response.length > 0){
            response.then((response) => {
                const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
                setImageUrl(imageUrls);
            });
            //   }

        }
    }, [token]);


    const fetchData = async () => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await fetch('https://folcon.saharaas.com/admin/all-coupons', config);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();

            let CouponData = [];

            if (Array.isArray(responseData)) {
                CouponData = responseData;
            } else if (Array.isArray(responseData.data)) {
                CouponData = responseData.data;
            } else {
                throw new Error('Data is not in the expected format');
            }
            SetCouponList(CouponData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const header = (


        <div className="table-header">
            <div>
                <h3>Coupon List</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                        />
                    </span>
                </div>
            </div>
            <div className="create-category-button">
                <button onClick={() => navigate('/admin/coupon-create')} className="btn btn-primary">Create coupon</button>
            </div>
        </div>
    );

    const handleEdit = async (CouponId) => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await axios.get(`https://folcon.saharaas.com/admin/get-coupon/${CouponId}`, config);
            const responseData = response.data;
            setCouponFormData({
                name: responseData.data.name,
                coupon_code: responseData.data.coupon_code,
                description: responseData.data.description,
                expire_date: responseData.data.expire_date,
                max_time_use: responseData.data.max_time_use,
                percentage: responseData.data.percentage,
                min_purchase_amount: responseData.data.min_purchase_amount,
                is_active: responseData.data.is_active,
                status: responseData.data.status,
                image_path: responseData.data.image_path


            });
            setSelectedCouponId(CouponId);
            const previewImageUrl = responseData.data.image_path ? appBaseUrl.concat(responseData.data.image_path) : null;
            setPreviewImage(previewImageUrl);
            setImag(responseData.data.image_path);
            openEditModal();


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const [editModalIsOpen, setEditModalIsOpen] = useState(false);


    const openEditModal = () => {
        setEditModalIsOpen(true);
    };

    const closeEditModal = () => {
        setEditModalIsOpen(false);
    };

    const [mediaModalIsOpen, setMediaModalIsOpen] = useState(false);

    const openMediaModal = () => {
        setMediaModalIsOpen(true);
    };

    const closeMediaModal = () => {
        setMediaModalIsOpen(false);
    };


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "expire_date") {
            // If it's a date field, split to get only the date part
            const date = value.split('T')[0];
            setCouponFormData({
                ...CouponFormData,
                [name]: date,
            });
        } else if (name === "coupon_code") {
            // Ensure the coupon code is only uppercase letters
            const uppercaseValue = value.toUpperCase();
            setCouponFormData({
                ...CouponFormData,
                [name]: uppercaseValue,
            });
        } else {
            // For other fields, simply update the state
            setCouponFormData({
                ...CouponFormData,
                [name]: value,
            });
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        // Function to check if the coupon code already exists
        const checkDuplicateCouponCode = (couponCode, couponId) => {
            return CouponList.some(coupon => coupon.coupon_code === couponCode && coupon.id !== couponId);
        };

        try {
            if (!CouponFormData.name || !CouponFormData.coupon_code) {
                toast.error('Please fill in all the required fields');
                return;
            }

            // Check for duplicate coupon code
            const isDuplicate = checkDuplicateCouponCode(CouponFormData.coupon_code, selectedCouponId);
            if (isDuplicate) {
                toast.error('Coupon code already exists. Please use a different code.');
                return;
            }

            const percentage = parseFloat(CouponFormData.percentage);
            const min_purchase_amount = parseInt(CouponFormData.min_purchase_amount);
            const max_time_use = parseInt(CouponFormData.max_time_use);

            const createPayload = {
                ...CouponFormData,
                name: CouponFormData.name,
                percentage: percentage,
                image_path: 'files/' + couponImage,
                max_time_use: max_time_use,
                min_purchase_amount: min_purchase_amount,
                coupon_id: selectedCouponId,
            };
            const newImageUrl = couponImage ? 'files/' + couponImage : CouponFormData.image_path;
            createPayload.image_path = newImageUrl;
            if (deletedImage === true) {
                createPayload.image_path = null;
            }

            const createResponse = await axios.post('https://folcon.saharaas.com/admin/update-coupon', createPayload, config);

            if (createResponse.data.status === "success") {
                toast.success('coupon updated successfully!');
                fetchData();
                setCouponFormData({
                    name: '',
                    coupon_code: '',
                    description: '',
                    expire_date: '',
                    percentage: '',
                    max_time_use: '',
                    min_purchase_amount: '',
                    is_active: 0
                });
            } else {
                toast.error('coupon update failed. Please try again.');
            }
        } catch (error) {
            console.error('Error updating coupon:', error);
            toast.error('coupon update failed. Please try again.');
        }
        closeEditModal();
    };
    const today = new Date().toISOString().split('T')[0];



    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        const activeValue = isChecked ? 1 : 0;
        setCouponFormData({
            ...CouponFormData,
            is_active: activeValue
        });
    };

    const columns = [
        { field: 'id', header: 'ID' },
        { field: 'coupon_code', header: 'Coupon Code' },
        { field: 'name', header: 'Name' },
        { field: 'expire_date', header: 'Expire On', body: (rowData) => formatDate(rowData.expire_date) },
        {
            field: 'image_path',
            header: 'Image',
            body: (rowData) => (
                <img
                    src={rowData.image_path ? (appBaseUrl + rowData.image_path) : noImage}
                    width={100}
                    height={100}
                    alt={rowData.image_path ? 'coupon' : 'No Image'}
                />
            )
        },
        {
            field: 'is_active',
            header: 'Status',
            body: (rowData) => (
                <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>
                    {rowData.is_active === 1 ? 'Active' : 'Inactive'}
                </span>
            )
        },
        {
            field: 'action',
            header: 'Action',
            body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a href="#" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" onClick={() => handleEdit(rowData.id)}>Edit info</a>
                            {/* <a className="dropdown-item text-danger" onClick={() => handleDelete(rowData)}>Delete</a> */}
                        </div>
                    </div>
                </div>
            )
        }
    ];


    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Coupon List</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Coupon</li>
                        <li className="breadcrumb-item active">Data</li>
                    </ol>
                </nav>

            </div>
            <section className="section">

                <ReactDataTable
                    data={currentCouponList}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    header={header}
                    emptyMessage="No coupon records found"
                    columns={columns}
                />
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />

            </section>
            <Modal
                isOpen={editModalIsOpen}
                onRequestClose={closeEditModal}
                contentLabel="Edit Coupon Info"
                style={{
                    overlay: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        position: 'relative',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        padding: '20px',
                        // maxWidth: '80%',
                        maxHeight: '100%',
                        overflowY: 'auto',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                    },
                }}
            >


                <div className='container' style={{ width: '900px' }}>
                    <div className="row">
                        <div className="col">
                            <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Coupon</h2>
                        </div>
                        <div className="col-auto"></div>
                        <button onClick={closeEditModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
                        </button>
                        <form className="row g-3" onSubmit={handleSubmit}>


                            <div className="col-md-6">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={CouponFormData.name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="coupon_code" className="form-label">
                                    Coupon Code
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="coupon_code"
                                    name="coupon_code"
                                    value={CouponFormData.coupon_code}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="description" className="form-label">
                                    Description
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    value={CouponFormData.description}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="expire_date" className="form-label">
                                    Expire On
                                </label>
                                <input
                                    type="date"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="expire_date"
                                    name="expire_date"
                                    value={CouponFormData.expire_date ? CouponFormData.expire_date.split('T')[0] : ''}
                                    min={today}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="percentage" className="form-label">
                                    percentage

                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control "
                                    id="percentage"
                                    name="percentage"
                                    value={CouponFormData.percentage}
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="max_time_use" className="form-label">
                                    max time use

                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control "
                                    id="max_time_use"
                                    name="max_time_use"
                                    value={CouponFormData.max_time_use}
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="min_purchase_amount" className="form-label">
                                    min purchase amount

                                </label>
                                <input
                                    type="number"
                                    placeholder="Type here"
                                    className="form-control "
                                    id="min_purchase_amount"
                                    name="min_purchase_amount"
                                    value={CouponFormData.min_purchase_amount}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="status" className="form-label">
                                    Coupon  Status
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="status"
                                    name="status"
                                    value={CouponFormData.status}
                                    onChange={handleChange}
                                    readOnly={true}
                                />
                            </div>


                            <div className="col-md-6">
                                <label htmlFor="toggle" className="form-label">
                                    Status
                                </label>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="toggle"
                                        name="is_active"
                                        checked={CouponFormData.is_active === 1}
                                        onChange={handleToggleChange}
                                    />
                                </div>
                            </div>
                            <div className="mb-4">

                                <div className="card-header">
                                    <h4>Preview</h4>
                                    <br />
                                    {previewImage ? (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", width: "200px", height: "200px" }} />

                                            <button
                                                type="button"
                                                onClick={handleDeletePreview}
                                                style={{
                                                    position: 'absolute',
                                                    top: '5px',
                                                    right: '5px',
                                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                                    border: 'none',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </button>
                                        </div>
                                    ) : (
                                        <img src={UploadImage} alt="Alt" />
                                    )}
                                    <br></br>
                                    <button type="button" className="btn btn-primary mt-2 btn-sm btn-block" onClick={openMediaModal}>
                                        Select Image
                                    </button>


                                </div>
                            </div>

                            <div className="text-center">
                                <button type="submit" className="btn btn-primary">Update</button>

                            </div>
                        </form>


                    </div>

                </div>
                <SingleImageSelect
                    isOpen={mediaModalIsOpen}
                    handleClose={closeMediaModal}
                    handleImageClick={handleImageClick}
                    selectedImages={selectedImages}
                    imageUrl={imageUrl}
                    token={token}
                    setImageUrl={setImageUrl}
                />
            </Modal>

        </main>
    );
};

export default CouponList;

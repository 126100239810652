import React, { useEffect, useState, useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { MediaImageUpload, MediaList, MultipleMediaUpload } from "../../service/apiService";
import { toast } from "react-toastify";
import Modal from "react-modal";
import ImageGalary from '../../assets/images/galary.jpg';

const ProductEditImageMedia = ({ productId, disabled }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageUrls, setSelectedImageUrls] = useState([]);
  const [listOrder, setListOrder] = useState([]);
  const token = localStorage.getItem("admin_token");
  const AppUrl = "https://folcon.saharaas.com/api/files/";
  const [imageUrl, setImageUrl] = useState([]);
  // const [disabled, disabled] = useState(false);
  const [updatedImageUrls, setUpdatedImageUrls] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [activeTab, setActiveTab] = useState('gallery');
  const [modalMessage, setModalMessage] = useState({ type: '', text: '' });
  const closeModal = () => setModalIsOpen(false);
  const openModal = () => setModalIsOpen(true);

  useEffect(() => {
    if (token) {
      const fetchImages = async () => {
        try {
          const response = await MediaList(token);
          const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
          setImageUrl(imageUrls);

          // const storedProductImages = JSON.parse(localStorage.getItem(`product_images_${productId}`));
          // if (storedProductImages) {
          //   setProductImages(storedProductImages);
          // }
          //  else {
          //   const productImageResponse = await MediaImageUpload(token, productId);
          //   const productImageUrls = productImageResponse.data.map(img => AppUrl + img);
          //   setProductImages(productImageUrls);
          //   localStorage.setItem(`product_images_${productId}`, JSON.stringify(productImageUrls));
          // }
        } catch (error) {
          console.error("Error fetching images:", error);
        }
      };
      fetchImages();
    }
  }, [token, productId]);

  const handleImageClick = (index) => {
    const selectedIndex = selectedImages.indexOf(index);
    if (selectedIndex === -1) {
      setSelectedImages([...selectedImages, index]);
    } else {
      setSelectedImages(selectedImages.filter((item) => item !== index));
    }
  };

  const openalert = () => {
    toast.error("Please Fill The Product Form Data");
  };

  const handleDeleteImage = (index) => {
    const updatedProductImages = productImages.filter((_, i) => i !== index);
    setProductImages(updatedProductImages);
    localStorage.setItem(`product_images_${productId}`, JSON.stringify(updatedProductImages));
    const updatedSelectedImages = selectedImages.filter((item) => item !== index);
    setSelectedImages(updatedSelectedImages);
  };

  const handleRemovePreviewImage = (index) => {
    const updatedPreviewImages = previewImages.filter((_, i) => i !== index);
    setPreviewImages(updatedPreviewImages);
    const updatedFiles = Array.from(selectedFile).filter((_, i) => i !== index);
    setSelectedFile(updatedFiles.length > 0 ? updatedFiles : null);
  };

  const moveImage = useCallback(
    (fromIndex, toIndex) => {
      setSelectedImages((prevImages) => {
        const updatedImages = [...prevImages];
        const [movedImage] = updatedImages.splice(fromIndex, 1);
        updatedImages.splice(toIndex, 0, movedImage);
        return updatedImages;
      });

      setListOrder((prevListOrder) => {
        const updatedListOrder = [...prevListOrder];
        const [movedOrder] = updatedListOrder.splice(fromIndex, 1);
        updatedListOrder.splice(toIndex, 0, movedOrder);
        return updatedListOrder;
      });
    },
    [selectedImages, listOrder]
  );

  const handleUpdateImage = async () => {
    const updatedImageUrls = selectedImages.map((index) => imageUrl[index]);
    const listOrderPayload = selectedImages.map((_, index) => index + 1);
    setSelectedImageUrls(updatedImageUrls);

    if (updatedImageUrls.length === 0 && productImages.length === 0) {
      setModalMessage({ type: 'error', text: "Please select at least one image" });
      return;
    }

    if (productImages.length + updatedImageUrls.length > 10) {
      setModalMessage({ type: 'error', text: "You can only upload a maximum of 10 images" });
      return;
    }


    const mediaList = await MediaImageUpload(
      token,
      productId,
      updatedImageUrls,
      listOrderPayload,
      0,
    );

    if (mediaList.status_code === 200) {
      toast.success(mediaList.message);
      setModalMessage({ type: 'success', text: mediaList.message });
      setSelectedImageUrls([]);
      setSelectedImages([]);
      setModalIsOpen(false);

      const newProductImages = [...productImages, ...mediaList.data.product_images.map(img => AppUrl + img)];
      setProductImages(newProductImages);
      localStorage.setItem(`product_images_${productId}`, JSON.stringify(newProductImages));
    } else {
      toast.error('Something went wrong. Please try again');
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif'];
    const validFiles = Array.from(files).filter(file => allowedTypes.includes(file.type));

    if (validFiles.length !== files.length) {
      setModalMessage({ type: 'error', text: "Only JPEG, PNG, WEBP and GIF formats are allowed" });
      setSelectedFile(null);
      setPreviewImages([]);
      return;
    }

    setSelectedFile(validFiles);

    const filePreviews = validFiles.map(file => URL.createObjectURL(file));
    setPreviewImages(filePreviews);
  };

  const handleMultiImageUpload = async () => {
    if (!selectedFile || selectedFile.length === 0) {
      setModalMessage({ type: 'error', text: "Please select images to upload" });
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append("file_name", selectedFile[i]);
    }

    try {
      const response = await MultipleMediaUpload(token, formData);
      if (response.status_code === 200) {
        setModalMessage({ type: 'success', text: "Images uploaded successfully" });

        const newImageUrls = response.data.uploaded_files.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
        setImageUrl(prevUrls => [...prevUrls, ...newImageUrls]);

        // setSelectedImages(prevSelectedImages => [...prevSelectedImages, ...Array(newImageUrls.length).keys()]);

        setSelectedFile(null);
        setPreviewImages([]);
      }
    } catch (error) {
      console.log(error);
      setModalMessage({ type: 'error', text: "Error uploading images" });
    }
  };



  const ItemTypes = {
    IMAGE: "image",
  };

  const DraggableImage = ({
    id,
    index,
    imageUrl,
    moveImage,
    handleDeleteImage,
  }) => {
    const [{ isDragging }, ref] = useDrag({
      type: ItemTypes.IMAGE,
      item: { id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: ItemTypes.IMAGE,
      hover: (item) => {
        if (item.index !== index) {
          moveImage(item.index, index);
        }
      },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
      <div
        ref={(node) => ref(drop(node))}
        className="position-relative mr-3 mb-3 draggable-image"
        style={{ opacity }}
      >
        <img
          src={imageUrl}
          className="img-fluid"
          alt={imageUrl}
          style={{ width: "200px", height: "200px" }}
        />
        <button
          className="btn btn-danger position-absolute top-0 end-0 delete-button"
          style={{ zIndex: "10" }}
          onClick={() => handleDeleteImage(id)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            {productImages.length > 0 && (
              <div className="col-lg-12 mt-3">
                <h3>Preview</h3>
                <div className="d-flex flex-wrap">
                  {productImages.map((imageUrl, index) => (
                    <div key={index} className="position-relative mr-3 mb-3">
                      <img
                        src={imageUrl}
                        className="img-fluid"
                        alt={`Uploaded Image ${index + 1}`}
                        style={{ width: "200px", height: "200px" }}
                      />
                      <button
                        className="btn btn-danger position-absolute top-0 end-0 delete-button"
                        style={{ zIndex: "10" }}
                        onClick={() => handleDeleteImage(index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <DndProvider backend={HTML5Backend}>
              <div className="d-flex flex-wrap">
                {selectedImages.map((index, i) => (
                  <DraggableImage
                    key={index}
                    id={index}
                    index={i}
                    imageUrl={AppUrl + imageUrl[index]}
                    moveImage={moveImage}
                    handleDeleteImage={handleDeleteImage}
                  />
                ))}
              </div>
            </DndProvider>
            <h4>Media Image</h4>
            {disabled ? (
              <>
                <div className="d-flex justify-content-center">
                  <img
                    className="btn btn-secondary ml-2 btn-sm"
                    style={{ textAlign: "center" }}
                    src={ImageGalary}
                    width={"250px"}
                    height={"250px"}
                    readOnly
                    onClick={openalert}
                  />
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <button
                    className="btn btn-secondary ml-2 btn-sm"
                    style={{ textAlign: "center" }}
                    disabled
                  >
                    Update Image
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <img
                    className="btn btn-secondary ml-2 btn-sm"
                    style={{ textAlign: "center" }}
                    onClick={openModal}
                    src={ImageGalary}
                    width={"250px"}
                    height={"250px"}
                  />
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <button
                    className="btn btn-secondary ml-2 btn-sm"
                    style={{ textAlign: "center" }}
                    onClick={handleUpdateImage}
                  >
                    Update Image
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Gallery"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "15px",
            padding: "20px",
            maxWidth: "1500px",
            minWidth: "1500px",
            maxHeight: "700px",
            minHeight: "800px",
            overflowY: "auto",
            boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)",
            border: "4px solid #F65C0A",

          },
        }}
      >
        <div className="container" style={{ width: "100%" }}>
          <div className="row">
            <div className="col">
              <h2 style={{ display: "inline-block", marginRight: "10px" }}>
                Image Gallery
              </h2>
            </div>
            <div className="col-auto">
              <button
                onClick={closeModal}
                style={{
                  fontSize: "30px",
                  marginTop: "0",
                  display: "flex",
                  justifyContent: "flex-end",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faTimes} style={{ color: "#63E6BE" }} />
              </button>
            </div>
          </div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 'gallery' ? 'active' : ''}`} onClick={() => setActiveTab('gallery')}>Image Media Gallery</button>
            </li>
            <li className="nav-item">
              <button className={`nav-link ${activeTab === 'upload' ? 'active' : ''}`} onClick={() => setActiveTab('upload')}>Upload Files</button>
            </li>
          </ul>
          <div className="tab-content">
            {activeTab === 'gallery' && (
              <div className="tab-pane active">
                <div className="row">
                  {imageUrl.map((image, index) => (
                    <div key={index} className="col-md-3 mb-3">
                      <div className="image-container"
                        style={{
                          width: "250px",
                          height: "250px",
                          border: "1px solid #ddd",
                          padding: "5px",
                          borderRadius: "5px",
                          position: "relative",
                          backgroundColor: selectedImages.includes(index)
                            ? "rgba(0,0,0,0.1)"
                            : "transparent",
                          cursor: "pointer",
                        }}
                        onClick={() => handleImageClick(index)}
                      >
                        <img
                          src={AppUrl + image}
                          className="img-fluid"
                          alt={image}
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                        <input
                          type="checkbox"
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            zIndex: "10",
                          }}
                          checked={selectedImages.includes(index)}
                          onChange={() => handleImageClick(index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {activeTab === 'upload' && (
              <div className="tab-pane active">
                <div className="row">
                  <div className="col">
                    <input type="file" multiple onChange={handleFileChange} />
                    {previewImages.length > 0 && (
                      <div className="mt-3">
                        <h5>Selected Images:</h5>
                        <div className="d-flex flex-wrap">
                          {previewImages.map((src, index) => (
                            <div key={index} className="mr-3 mb-3 position-relative">
                              <img
                                src={src}
                                alt={`Preview ${index}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                  border: "1px solid #ddd",
                                  padding: "5px",
                                }}
                              />
                              <button
                                className="btn btn-danger position-absolute top-0 end-0"
                                style={{ zIndex: "10" }}
                                onClick={() => handleRemovePreviewImage(index)}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {modalMessage.text && (
                      <div className={`alert mt-3 ${modalMessage.type === 'error' ? 'alert-danger' : 'alert-success'}`}>
                        {modalMessage.text}
                      </div>
                    )}
                    <button className="btn btn-primary mt-3" onClick={handleMultiImageUpload}>Upload Images</button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-auto d-flex justify-content-end">
            <button
              onClick={closeModal}
              style={{
                fontSize: "30px",
                marginTop: "0",
                display: "flex",
                justifyContent: "flex-end",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "5px",
                background: "#F1E1D9",
                cursor: "pointer",
              }}
            >
              Close Model
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductEditImageMedia;

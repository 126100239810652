import { faCircleXmark, faStreetView } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactDataTable from "../../../components/DataTable";
import ReactPaginate from "react-paginate";
import Modal from 'react-modal';
import { GetSubPagesList } from "../../../service/apiService";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const SubPageList = () => {


    const [SubPages, setSubPages] = useState([])
    const [globalFilter, setGlobalFilter] = useState("");

    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedSubpageId, setselectedSubpageId] = useState(null);
    const itemsPerPage = 10;


    const [SubPageformData, setSubPageFormData] = useState({
        page_name: "",
        page_content: "",
        is_active: 1
    });


    const AppUrl = "https://folcon.saharaas.com/api/files/";
    const navigate = useNavigate();


    const fetchSubPagesData = async () => {
        try {
            const token = localStorage.getItem("admin_token");
            const response = await GetSubPagesList(token);
            setSubPages(response.data)
        } catch (error) {
            console.log(error)
        }
    }


    // ***********************
    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        try {
            if (!SubPageformData.page_name || !SubPageformData.page_content) {
                toast.error('Please fill in all the required fields');
                return;
            }

            const createPayload = {
                ...SubPageformData,
                id: selectedSubpageId,
                page_name: SubPageformData.page_name,
                page_content: SubPageformData.page_content,
                is_active: SubPageformData.is_active
            };

            const createResponse = await axios.post('https://folcon.saharaas.com/admin/update-sub-page', createPayload, config);
            console.log(createResponse)

            if (createResponse.data.status === "success") {
                toast.success('Subpage updated successfully!');
                fetchSubPagesData();
                setSubPageFormData({
                    page_name: "",
                    page_content: "",
                    is_active: 1
                });
            } else {
                toast.error('coupon update failed. Please try again.');
            }
        } catch (error) {
            console.error('Error updating coupon:', error);
            toast.error('coupon update failed. Please try again.');
        }
        closeEditModal();
    };


    // ***********************


    const pageCount = Math.ceil(SubPages.length / itemsPerPage);
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };
    const currentSubPages = SubPages.slice(
        pageNumber * itemsPerPage,
        (pageNumber + 1) * itemsPerPage
    )



    useEffect(() => {
        fetchSubPagesData();
    }, [])




    const handleChange = (event) => {
        const { id, value } = event.target;
        if (id === "page_name") {
            const categoryName = value.replace(/[^a-zA-Z\s]/g, "");
            setSubPageFormData((prevData) => ({
                ...prevData,
                [id]: categoryName,
            }));
        } else {
            setSubPageFormData((prevData) => ({
                ...prevData,
                [id]: value,
            }));
        }
    };


    const handleEdit = async (SubPageId) => {
        try {
            const token = localStorage.getItem("admin_token");
            const csrfToken = localStorage.getItem("csrfToken");

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "X-CSRF-Token": csrfToken,
                },
            };

            const response = await axios.get(
                `https://folcon.saharaas.com/admin/get-single-sub-page/${SubPageId}`,
                config
            );
            const responseData = response.data;
            console.log(responseData)

            setSubPageFormData({
                page_name: responseData.data.page_name,
                page_content: responseData.data.page_content,
                is_active: responseData.data.is_active,

            });
            setselectedSubpageId(SubPageId);
            openEditModal()
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const openEditModal = () => {
        setEditModalIsOpen(true);
    };



    const closeEditModal = () => {
        setEditModalIsOpen(false);
    };

    const handleDelete = () => {

    }

    const header = (
        <div className="table-header">
            <div>
                <h3>Sub Pages</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                        />
                    </span>
                </div>
            </div>
            <div className="create-category-button">
                <button
                    onClick={() => navigate("/admin/appearance-subpageCreate")}
                    className="btn btn-primary"
                >
                    Create Sub Pages
                </button>
            </div>
        </div>
    );


    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        const activeValue = isChecked ? 1 : 0;
        setSubPageFormData({
            ...SubPageformData,
            is_active: activeValue
        });
    };


    const columns = [
        { field: "page_id", header: "ID", sortable: true },
        { field: "page_name", header: "Sub page_name", sortable: true },
        {
            field: "action",
            header: "Action",
            body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a
                            href="#"
                            data-bs-toggle="dropdown"
                            className="btn btn-light rounded btn-sm font-sm"
                        >
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a

                                className="dropdown-item edit-icon"
                                onClick={() => handleEdit(rowData.page_id)}
                                style={{ cursor: "pointer" }}
                            >
                                Edit info
                            </a>
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <>

            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Sub Pages  List</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/dashboard">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item">SubPages</li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>
                <ReactDataTable
                    data={currentSubPages}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    header={header}
                    emptyMessage="No SubPage records found"
                    columns={columns}
                />
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
            </main>
            <Modal
                isOpen={editModalIsOpen}
                onRequestClose={closeEditModal}
                contentLabel="Edit Subpage"
                style={{
                    overlay: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                    content: {
                        position: "relative",
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        padding: "20px",
                        maxWidth: "90%",
                        maxHeight: "90%",
                        overflowY: "auto",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                    },
                }}
            >
                <div className="container" style={{ width: "900px" }}>
                    <div className="row">
                        <div className="col">
                            <h2 style={{ display: "inline-block", marginRight: "10px" }}>
                                <strong>
                                    Edit Sub Page
                                </strong>
                            </h2>
                        </div>
                        <div className="col-auto">
                            <button
                                onClick={closeEditModal}
                                style={{
                                    fontSize: "30px",
                                    marginTop: "0",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    border: "none",
                                    background: "none",
                                    cursor: "pointer",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    style={{ color: "#63E6BE" }}
                                />
                            </button>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        {/* {Tittle } */}
                        <div className="mb-4">
                            <label htmlFor="name" className="form-label">
                                page_name
                            </label>
                            <input
                                type="text"
                                placeholder="Type here"
                                className="form-control"
                                id="page_name"
                                value={SubPageformData.page_name}
                                onChange={handleChange}
                            />
                        </div>

                        {/* {CknEditor Text field } */}
                        <div className="mb-4">
                            <label htmlFor="description" className="form-label">
                                page_content
                            </label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={SubPageformData.page_content}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setSubPageFormData((prevData) => ({
                                        ...prevData,
                                        page_content: data
                                    }));
                                }}
                                config={{
                                    toolbar: [
                                        'heading', '|',
                                        'bold', 'italic', 'blockQuote', '|',
                                        'numberedList', 'bulletedList', '|',
                                        'undo', 'redo'
                                    ],
                                }}
                            />
                        </div>
                        {/* {Toggle Bar} */}
                        <div className="mb-4">
                            <label htmlFor="toggle" className="form-label">
                                Status
                            </label>
                            <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="toggle"
                                    checked={SubPageformData.is_active === 1}
                                    onChange={handleToggleChange}
                                />
                            </div>
                        </div>
                        <br />
                        <button type="submit" className="btn btn-primary">
                            Update
                        </button>
                    </form>
                </div>
            </Modal>
        </>
    )
}

export default SubPageList;









import React, { useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InputCheckbox from "../../../components/Inputcheckbox";
import Input from "../../../components/Input";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function SubPageCreate() {
    const token = localStorage.getItem("admin_token");

    const navigate = useNavigate()
    const csrfToken = localStorage.getItem('csrfToken');

    // CKEditor state
    const [description, setDescription] = useState('');

    // Form state
    const [formData, setFormData] = useState({
        page_name: "",
        is_active: 0
    });

    // Handle input change
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    // Handle toggle change
    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        setFormData((prevData) => ({
            ...prevData,
            is_active: isChecked ? 1 : 0
        }));
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        const payload = {
            page_name: formData.page_name,
            page_content: description,
            is_active: formData.is_active
        };

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        try {
            const response = await axios.post('https://folcon.saharaas.com/admin/create-sub-page', payload, config);
            if (response.data.status === "success") {
                toast.success('Sub-page created successfully!');
            } else {
                toast.error('Sub-page creation failed. Please try again.');
            }
        } catch (error) {
            console.error('Error creating sub-page:', error);
            toast.error('Sub-page creation failed. Please try again.');
        }
        navigate(`/admin/appearance-subpage`)
    };

    return (
        <>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Sub Pages</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/dashboard">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item">Appearance</li>
                            <li className="breadcrumb-item active">Create</li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <form className="row g-3" onSubmit={handleSubmit}>

                                        {/* Title */}
                                        <div className="col-md-6">
                                            <label htmlFor="page_name" className="form-label">
                                                Title
                                            </label>
                                            <Input
                                                type="text"
                                                placeholder="Type here"
                                                className="form-control"
                                                id="page_name"
                                                value={formData.page_name}
                                                onChange={handleInputChange}
                                            />
                                        </div>

                                        {/* CKEditor */}
                                        <div className="col-md-12">
                                            <label htmlFor="description" className="form-label">
                                                Description
                                            </label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={description}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setDescription(data);
                                                }}
                                                config={{
                                                    toolbar: [
                                                        'heading', '|',
                                                        'bold', 'italic', 'blockQuote', '|',
                                                        'numberedList', 'bulletedList', '|',
                                                        'undo', 'redo'
                                                    ],
                                                }}
                                            />
                                        </div>

                                        {/* Toggle Bar */}
                                        <div className="col-md-6">
                                            <label
                                                className="form-check-label"
                                                htmlFor="toggle"
                                            >
                                                Status
                                            </label>
                                            <div className="form-check form-switch">
                                                <InputCheckbox
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="toggle"
                                                    checked={formData.is_active === 1}
                                                    onChange={handleToggleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <button type="submit" className="btn btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

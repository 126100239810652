import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { MediaList } from '../../service/apiService';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const VideoGallery = ({ isOpen, onRequestClose, onMediaSelect, selectedMedia, setSelectedMedia }) => {
  const [mediaUrls, setMediaUrls] = useState([]);
  const token = localStorage.getItem('admin_token');
  const AppUrl = 'https://folcon.saharaas.com/api/files/';

  useEffect(() => {
    if (token) {
      MediaList(token).then((response) => setMediaUrls(response.data));
    }
  }, [token]);

  const handleMediaCheckboxChange = (mediaUrl) => {
    if (selectedMedia.includes(mediaUrl)) {
      setSelectedMedia(selectedMedia.filter((url) => url !== mediaUrl));
    } else {
      setSelectedMedia([...selectedMedia, mediaUrl]);
    }
  };

  const handleUpdateMedia = () => {
    const updatedMediaUrls = selectedMedia.map((index) => mediaUrls[index]);
    if (updatedMediaUrls.length === 0) {
      toast.error('Please select at least one media');
      return;
    }
    onMediaSelect(updatedMediaUrls);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Video Gallery"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          backgroundColor: '#fff',
          borderRadius: '10px',
          padding: '20px',
          maxWidth: '800px',
          maxHeight: '600px',
          overflowY: 'auto',
        },
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Video Gallery</h2>
          </div>
          <div className="col-auto">
            <button onClick={onRequestClose} style={{ fontSize: '30px', border: 'none', background: 'none', cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faTimes} style={{ color: '#63E6BE' }} />
            </button>
          </div>
        </div>
        <div className="row">
          {mediaUrls
            .filter((url) => url.endsWith('.mp4') || url.endsWith('.mov') || url.endsWith('.avi'))
            .map((mediaUrl, index) => (
              <div key={index} className="col-md-4 mb-4">
                <div style={{ width: '200px', height: 'auto', border: '1px solid #ddd', padding: '5px', borderRadius: '5px', position: 'relative' }}>
                  <video src={AppUrl + mediaUrl} className="img-fluid" controls />
                  <input
                    type="checkbox"
                    style={{ position: 'absolute', top: '5px', right: '5px', zIndex: '10' }}
                    checked={selectedMedia.includes(mediaUrl)}
                    onChange={() => handleMediaCheckboxChange(mediaUrl)}
                  />
                </div>
              </div>
            ))}
        </div>
        {/* <button className="btn btn-secondary mt-3" onClick={handleUpdateMedia}>Update Media</button> */}
      </div>
    </Modal>
  );
};

export default VideoGallery;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import UploadImage from '../../../assets/images/defimg.jpeg';
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import { MediaList } from "../../../service/apiService";

const Ads = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("admin_token");

  const [selectedColor, setSelectedColor] = useState("#000000");
  const [isColorPickerDisabled, setIsColorPickerDisabled] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [responsiveImage, setresponsiveImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedresonsiveImages, setSelectedresonsiveImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [adsImage, setAdsImage] = useState(null);
  const [adsresponsiveImage, setAdsresponsiveImage] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [responsiveUrl, setresponsiveUrl] = useState([]);
  const [modalIsOpens, setModalIsOpens] = useState(false);


  const AppUrl = "https://folcon.saharaas.com/api/files/";

  const [AdsformData, setAdsFormData] = useState({
    name: "",
    slug: "",
    background_color: "",
    title_one: "",
    title_two: "",
    description_one: "",
    description_two: "",
    link_one: "",
    link_two: "",
    is_active: 1
  });

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (token) {
      MediaList(token).then((response) => {
        const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
        setImageUrl(imageUrls);
      });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      MediaList(token).then((response) => {
        const responsiveUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
        setresponsiveUrl(responsiveUrls);
      });
    }
  }, [token]);

  const handleToggleChange = (e) => {
    setAdsFormData({
      ...AdsformData,
      is_active: e.target.checked ? 1 : 0
    });
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setAdsFormData({
      ...AdsformData,
      background_color: color,
    });
  };

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };

  const handleDeletePreviews = () => {
    setresponsiveImage(null);
    setSelectedresonsiveImages([]);
  };


  const handleChange = (e) => {
    const { id, value } = e.target;
    setAdsFormData({
      ...AdsformData,
      [id]: value,
      ...(id === 'name' && { slug: generateSlug(value) })
    });

    setFormErrors({
      ...formErrors,
      [id]: ""
    });

    if (id === 'name' && value.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required"
      }));
    }
    if (id === 'link_one' && value.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        link_one: "Link one is required"
      }));
    }
    if (id === 'link_two' && value.trim() === "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        link_two: "Link two is required"
      }));
    }

    if (id === 'background_color') {
      setSelectedColor(value);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  //const openModals = () => setModalIsOpens(true);
  const closeModals = () => setModalIsOpens(false);

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      setSelectedImages(selectedImages.filter(i => i !== index));
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setAdsImage(imageUrl[index]);
      closeModal();
    }
  };

  const handleImageClicks = (index) => {
    if (selectedresonsiveImages.includes(index)) {
      setSelectedresonsiveImages(selectedresonsiveImages.filter(i => i !== index));
    } else {
      setSelectedresonsiveImages([index]);
      setresponsiveImage(AppUrl + responsiveUrl[index]);
      setAdsresponsiveImage(responsiveUrl[index]);
      closeModals();
    }
  };

  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, '-');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');

    if (AdsformData.name.trim() === "" || AdsformData.link_one.trim() === "" || AdsformData.link_two.trim() === "") {
      setFormErrors({
        ...formErrors,
        name: AdsformData.name.trim() === "" ? "Name is required" : "",
        link_one: AdsformData.link_one.trim() === "" ? "Link one is required" : "",
        link_two: AdsformData.link_two.trim() === "" ? "Link two is required" : ""
      });
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {
      if (!AdsformData.name || !AdsformData.slug) {
        toast.error('Please Fill The Form Data');
        return;
      }

      const createPayload = {
        ...AdsformData,
        image_path: adsImage,
        responsive_image_path: adsresponsiveImage,
      };

      const createResponse = await axios.post('https://folcon.saharaas.com/admin/create-ad', createPayload, config);

      if (createResponse.data.status === "success") {
        setPreviewImage(null);
        setresponsiveImage(null);
        toast.success('Ad created successfully!');
        setAdsFormData({
          name: "",
          slug: "",
          background_color: "",
          title_one: "",
          title_two: "",
          description_one: "",
          description_two: "",
          link_one: "",
          link_two: "",
          is_active: 1
        });
        navigate('/admin/appearance-adslist');
      } else {
        toast.error('Ad creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating ad:', error);
      toast.error('Ad creation failed. Please try again.');
    }
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Ads</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Appearance</li>
            <li className="breadcrumb-item active">Ads</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-10">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-6">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Title</label>
                    <div className="col-sm-8">
                      <input id="name" name="name" type="text" className="form-control" placeholder="Type here" onChange={handleChange} />
                      {formErrors.name && <span className="text-danger">{formErrors.name}</span>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="slug" className="col-sm-2 col-form-label">Slug</label>
                    <div className="col-sm-8">
                      <input id="slug" name="slug" type="text" className="form-control" placeholder="Type here" value={AdsformData.slug} readOnly />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="description_one" className="col-form-label">Description One</label>
                    <div className="col-sm-8">
                      <input id="description_one" name="description_one" type="text" placeholder="Type here" className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="description_two" className="col-form-label">Description Two</label>
                    <div className="col-sm-8">
                      <input id="description_two" name="description_two" type="text" placeholder="Type here" className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="title_one" className="col-form-label">Title One</label>
                    <div className="col-sm-8">
                      <input id="title_one" name="title_one" type="text" placeholder="Type here" className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="title_two" className="col-form-label">Title Two</label>
                    <div className="col-sm-8">
                      <input id="title_two" name="title_two" type="text" placeholder="Type here" className="form-control" onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="background_color" className="col-sm-2 col-form-label">Color</label>
                    <div className="col-sm-8">
                      <div className="input-group">
                        <input
                          id="background_color"
                          name="background_color"
                          type="text"
                          placeholder="Choose Color"
                          className="form-control"
                          value={selectedColor}
                          onChange={handleChange}
                          disabled={isColorPickerDisabled}
                        />
                        <input
                          type="color"
                          value={selectedColor}
                          onChange={(e) => handleColorChange(e.target.value)}
                          className="color-picker"
                          style={{ border: `2px solid ${selectedColor}`, borderRadius: "5px", backgroundColor: selectedColor, color: '#000', borderLeft: 'none', padding: '0px 5px' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="toggle" className="col-sm-2 col-form-label">Status</label>
                    <div className="col-sm-4">
                      <input className="form-check-input" type="checkbox" id="toggle" checked={AdsformData.is_active === 1} onChange={handleToggleChange} />
                      <label htmlFor="toggle" className="form-check-label">{AdsformData.is_active === 1 ? "Active" : "Inactive"}</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="link_one" className="col-form-label">Ads Link One</label>
                    <div className="col-sm-8">
                      <input id="link_one" name="link_one" type="text" placeholder="Type here" className="form-control" onChange={handleChange} />
                      {formErrors.link_one && <span className="text-danger">{formErrors.link_one}</span>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="link_two" className="col-form-label">Ads Link Two</label>
                    <div className="col-sm-8">
                      <input id="link_two" name="link_two" type="text" placeholder="Type here" className="form-control" onChange={handleChange} />
                      {formErrors.link_two && <span className="text-danger">{formErrors.link_two}</span>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Preview</label>
                    <br />
                    {previewImage ? (
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", width: "200px", height: "200px" }} />
                        <button type="button" onClick={handleDeletePreview}
                          style={{ position: 'absolute', top: '5px', right: '5px', backgroundColor: 'rgba(255, 255, 255, 0.7)', border: 'none', cursor: 'pointer' }}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    ) : (
                      <img src={UploadImage} alt="Alt" />
                    )}
                    <br />
                    <button type="button" className="btn btn-primary mt-2 btn-sm btn-block" onClick={() => setModalIsOpen(true)}>
                      Select Image
                    </button>
                  </div>

                  <div className="col-md-6">
                    <label>Responsive Image</label>
                    <br />
                    {responsiveImage ? (
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        <img src={responsiveImage} alt="Preview" style={{ maxWidth: "100%", width: "200px", height: "200px" }} />
                        <button type="button" onClick={handleDeletePreviews}
                          style={{ position: 'absolute', top: '5px', right: '5px', backgroundColor: 'rgba(255, 255, 255, 0.7)', border: 'none', cursor: 'pointer' }}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    ) : (
                      <img src={UploadImage} alt="Alt" />
                    )}
                    <br />
                    <button type="button" className="btn btn-primary mt-2 btn-sm btn-block" onClick={() => setModalIsOpens(true)}>
                      Select Image
                    </button>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <SingleImageSelect
            isOpen={modalIsOpen}
            handleClose={() => setModalIsOpen(false)}
            handleImageClick={handleImageClick}
            selectedImages={selectedImages}
            imageUrl={imageUrl}
            token={token}
            setImageUrl={setImageUrl}
          />

          <SingleImageSelect
            isOpen={modalIsOpens}
            handleClose={() => setModalIsOpens(false)}
            handleImageClick={handleImageClicks}
            selectedImages={selectedresonsiveImages}
            imageUrl={responsiveUrl}
            token={token}
            setImageUrl={setresponsiveUrl}
          />
        </div>
      </section>
    </main>
  );
}

export default Ads;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView } from "@fortawesome/free-solid-svg-icons";
import { allProducts, deleteProduct } from "../../../service/apiService";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noImage from "../../../assets/images/noimage.jpg";

const ProductList = () => {
  const navigate = useNavigate();
  const [ProductList, setProduct] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const AppUrl = "https://folcon.saharaas.com/api/files/";
  const itemsPerPage = 10;
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const responseData = await allProducts(token);
      let productData = [];

      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setProduct(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (product_id) => {
    navigate(`/admin/product-edit`, { state: { product_id } });
  };

  const handleView = (product_id) => {
    navigate(`/admin/product-view`, { state: { product_id } });
  };

  const header = (
    <div className="table-header">
      <div>
        <h3>Products</h3>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <input
              type="search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
              className="p-inputtext p-component"
            />
          </span>
        </div>
      </div>
      <div className="create-category-button">
        <button onClick={() => navigate('/admin/product-create')} className="btn btn-primary">
          Create Products
        </button>
      </div>
    </div>
  );

  const columns = [
    { field: "sno", header: "S.No" },
    {
      field: "product_name",
      header: "Product Name",
      body: (rowData) => (
        <span>
          {rowData.product_name} <br />
          <b>SKU:</b> {rowData.sku}
        </span>
      ),
    },
    {
      field: "quantity",
      header: "Quantity",
      body: (rowData) => (
        <span>
          {rowData.minimum_stock_warning && rowData.quantity <= rowData.minimum_stock_warning ? (
            <span style={{ color: "red" }}>
              {rowData.quantity} - Minimum Stock Warning
            </span>
          ) : (
            rowData.quantity
          )}
        </span>
      ),
    },
    {
      field: "thumnail_image",
      header: "Image",
      body: (rowData) => (
        <img
          src={rowData.thumnail_image.startsWith(AppUrl) ? rowData.thumnail_image : `${AppUrl}${rowData.thumnail_image}` || noImage}
          width={100}
          height={100}
          alt="Product"
        />
      ),
    },
    { field: "slug", header: "Slug" },
    {
      field: "is_active",
      header: "Status",
      body: (rowData) => (
        <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>
          {rowData.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      field: "action",
      header: "Action",
      body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a href="#" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
              <FontAwesomeIcon icon={faStreetView} />
            </a>
            <div className="dropdown-menu">
              <a className="dropdown-item" onClick={() => handleEdit(rowData.product_id)}>
                Edit info
              </a>
              <a className="dropdown-item" onClick={() => handleView(rowData.product_id)}>
                View info
              </a>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const filteredProducts = ProductList.filter((product) =>
    product.product_name.toLowerCase().includes(globalFilter.toLowerCase())
  );

  const pageCount = Math.ceil(filteredProducts.length / itemsPerPage);
  const currentProductList = filteredProducts.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  ).map((product, index) => ({
    ...product,
    sno: pageNumber * itemsPerPage + index + 1,
  }));

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Product List</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
            <li className="breadcrumb-item">Product</li>
            <li className="breadcrumb-item active">Data</li>
          </ol>
        </nav>
      </div>
      <ReactDataTable
        data={currentProductList}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        header={header}
        emptyMessage="No Products records found"
        columns={columns}
      />
      <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
    </main>
  );
};

export default ProductList;
